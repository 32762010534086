import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaCarosle from "../../Components/SpaCarosle";
import Post1 from "../../assets/images/Spa/Post-1.png";
import axios from "../../axios";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import Slide from "react-reveal/Slide";
import { useTranslation } from "react-i18next";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import BannerServices from "../../new-components/banner-home/BannerServices";

function SalonAtHomeUUID() {
  const [loading, setLoading] = useState(false);
  const [modalImage, setModalImage] = useState(Post1);
  const [showCart, setShowCart] = useState(false);
  const [show, setShow] = useState(false);
  const [headerImages, setHeaderImages] = useState();
  const [salon_uuid, setSalon_uuid] = useState();
  const [title, setTitle] = useState();
  const [spaServices, setSpaServices] = useState([]);
  const [salonId, setSalonId] = useState();
  const location = useLocation();
  const rawPath = location.pathname;
  const navigate = useNavigate();
  const room_uuid = rawPath.split("/")[1];
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";


  const { t } = useTranslation();
  const params = useParams();
  const handleShow = () => setShow(true);
  const handleCartShow = () => setShowCart(true);
  const lang = localStorage.getItem("lang");

  // console.log("params.uuid", params.uuid);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      axios
        .get(`/${room_uuid}/salon_at_home/${params.uuid}`, {
          headers: {
            lang: lang,
          },
        })

        .then((res) => {
          console.log("res", res);
          if (res.data.status === false) {
            navigate(`/${room_uuid}/qr-scan`);
          } else {
            const data = res.data.data;
            setSalon_uuid(data.salonAtHome.uuid);
            setHeaderImages(data.banners);
            setSpaServices(data.salonAtHome.services);
            setSalonId(data.salonAtHome.id);
            setTitle(data.center_header);
            console.log("MY", data);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err?.response.status == 404) {
            navigate(`/not-found`);
          }
        });
    };
    getData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  const show_prices = localStorage.getItem("show_prices");
  console.log("show_prices", show_prices);
  // console.log("spaser", spaServices);
  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={title} /> : <HeaderInner headerName={title} />}
      <main className="home-main bg-white back-white pad-top-0 pb-30">
        {loading ? (
          <SpaLoader />
        ) : (
          <>
            {headerImages?.length > 0 && theme ? (
              <BannerServices
                className={"px-2"}
                headerImages={[headerImages]}
              />
            ) : (
              <SpaCarosle
                noDots={true}
                autoPlay={true}
                isSalon={true}
                salonImg={headerImages}
              />
            )}
            {spaServices &&
              spaServices.map(({ image, name, price, id, extras }) => {
                return (
                  <Slide bottom>
                    <div className="add-post-container add-cart" key={id}>
                      <div className="add-cart-post new spa ">
                        <div className="add-cart-post-inner">
                          <div className="d-none"></div>
                          <span
                            className="img-pnl navigate"
                            onClick={() => {
                              handleShow();
                              setModalImage(image);
                            }}
                          >
                            <img src={image} alt="Post" />
                          </span>
                          <div
                            className="txt-pnl navigate"
                            onClick={handleCartShow}
                          >
                            <h2>{name}</h2>
                            <p></p>
                            <div className="flex-div align-item-center">
                              <h3>
                                {show_prices == "true" ? (
                                  extras.length > 1 ? (
                                    <span style={{ fontSize: "12px" }}>
                                      {t("Price upon selection")}
                                    </span>
                                  ) : (
                                    <span style={{ fontSize: "12px" }}>
                                      {t("Price upon selection")}
                                    </span>
                                  )
                                ) : null}
                              </h3>
                              <span
                                onClick={() =>
                                  navigate(
                                    `/${room_uuid}/SATAddService/${salon_uuid}/${salonId}/${id}`,
                                    {
                                      state: { theme: theme, id: id },
                                    }
                                  )
                                }
                                // to={`/${room_uuid}/SATAddService/${salon_uuid}/${salonId}/${id}`}
                                state={id}
                                className="reg-btn service"
                              >
                                {t("Add Service")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slide>
                );
              })}
          </>
        )}
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default SalonAtHomeUUID;
