import React, { useEffect, useState } from "react";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";
import { phoneFormat } from "../../constants/api";

const ModalMaintenance = ({
  show,
  setShow,
  category,
  room_uuid,
  text,
  theme,
  company_id
}) => {
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [errPhone, setErrPhone] = useState("");
  const [responseLinks, setResponseLinks] = useState([]);


  const { t } = useTranslation();

  const navigate = useNavigate();
  const handelChangImage = async (e) => {
    setLoading(true);
    const formData = new FormData();
    // console.log("imagessssssssssss", e.target.files[0]);
    setFiles([...files, e.target.files[0]]);
    // setImage(e.target.files[0]);
    formData.append("file", e.target.files[0]);
    // console.log("files", files);

    await axios
      .post(`/${room_uuid}/maintenance/upload-document`, formData)
      .then((res) => {
        // images.push(res.data.data);

        console.log("res.data.data", res.data.data);
        setResponseLinks((prevLinks) => [...prevLinks, res.data.data]);
        setPreviewUrls([
          ...previewUrls,
          URL.createObjectURL(e.target.files[0]),
        ]);
        setLoading(false);
        // console.log("imagesimages", responseLinks);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
    // console.log("imagesimages", responseLinks);
  };
  useEffect(() => {
    setResponseLinks([]);
    setFiles([]);
    setPreviewUrls([]);
    setPhone("");
  }, [category]);

  const handleClose = () => {
    setShow(false);
    setResponseLinks([]);
    setFiles([]);
    setPhone("");
    setPreviewUrls([]);
  };

  const dataForm = {
    phone: phone.phone,
    note: note,
    category: category,
    company: company_id,
    maintenance_images: Object.values(responseLinks),
  };


  const handelBook = () => {
    setIsDisabled(true);
    axios
      .post(`/${room_uuid}/maintenance/book-category`, dataForm)
      .then((res) => {
        console.log("ressssss", res);
        if (res.data.status) {
          navigate(`/${room_uuid}/MaintenanceOrderPlaced`, {
            state: {
              metadata: {
                res: res.data.data,
              },
            },
          });
          setResponseLinks([]);
          setFiles([]);
          setPreviewUrls([]);
          setPhone("");
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(false);
        console.log(err);
      });
  };
  const removeItem = (index) => {
    console.log("this index", index);
    // Create a new array without the item at the specified index
    const updatedResponseLink = [
      ...responseLinks.slice(0, index),
      ...responseLinks.slice(index + 1),
    ];
    const updatedPreviewUrls = [
      ...previewUrls.slice(0, index),
      ...previewUrls.slice(index + 1),
    ];

    setResponseLinks(updatedResponseLink);
    setPreviewUrls(updatedPreviewUrls);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={`custom-width pt-4 ${theme ? "food-modal" : ""}`}
    >
      <Modal.Body>
        <button
          type="button"
          className="button close-pop"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.054"
            height="24.625"
            viewBox="0 0 24.054 24.625"
          >
            <defs></defs>
            <path
              id="Icon_ionic-ios-close-circle"
              data-name="Icon ionic-ios-close-circle"
              className="cls-50"
              d="M15.4,3.375A12.17,12.17,0,0,0,3.375,15.687,12.17,12.17,0,0,0,15.4,28,12.17,12.17,0,0,0,27.429,15.687,12.17,12.17,0,0,0,15.4,3.375Zm3.047,16.77L15.4,17.025l-3.047,3.12a.913.913,0,0,1-1.307,0,.959.959,0,0,1,0-1.338l3.047-3.12-3.047-3.12a.959.959,0,0,1,0-1.338.913.913,0,0,1,1.307,0L15.4,14.35l3.047-3.12a.913.913,0,0,1,1.307,0,.966.966,0,0,1,0,1.338l-3.047,3.12,3.047,3.12a.966.966,0,0,1,0,1.338A.9.9,0,0,1,18.449,20.145Z"
              transform="translate(-3.375 -3.375)"
            ></path>
          </svg>
        </button>
        <div className="body-container maintenance ">
          {/* <div className="img-pnl">
            <img className="mod_img" src={imageMod} />
          </div> */}
          <h4>{text}</h4>
          <div className="content-pnl">
            <div className="input-pnl">
              <Row>
                <Col xs="12">
                  <Form.Group className="mb-3 dark-placeholder i-f-small w-100">
                    <PhoneInput
                      country={phoneFormat}
                      containerclassName=" "
                      placeholder="00144"
                      onChange={(phone) => {
                        setPhone((prev) => ({ ...prev, phone }));
                      }}
                    />
                  </Form.Group>
                  {errPhone !== "" && (
                    <span className="err_msg">{t(`${errPhone}`)}</span>
                  )}
                </Col>
                <Col xs="12">
                  <Form.Group className="mb-3 dark-placeholder i-f-small w-100">
                    <Form.Control
                      as="textarea"
                      placeholder={t(`Your Notes (Optional)`)}
                      name="note"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      style={{ height: "106px" }}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12">
                  {loading ? (
                    <>
                      <label htmlFor="choseImage" className="chose_img_mani">
                        Loading...
                      </label>
                      <div htmlFor="choseImagem" className="droupzo_">
                        {/* Loading */}
                        <div className="drop_c">
                          {previewUrls.map((url, index) => (
                            <div>
                              <img key={index} src={url} alt="" />
                              <i
                                class="fas fa-times"
                                onClick={() => removeItem(index)}
                              ></i>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <label htmlFor="choseImage" className="chose_img_mani">
                        <i class="fas fa-images"></i> {t("Attach Images")}
                      </label>
                      <div className="droupzo_">
                        <div className="drop_c">
                          {previewUrls.map((url, index) => (
                            <div>
                              <img key={index} src={url} alt="" />
                              <i
                                class="fas fa-times"
                                onClick={() => removeItem(index)}
                              ></i>
                            </div>
                          ))}
                        </div>
                      </div>
                      <input
                        type="file"
                        id="choseImage"
                        className="d-none"
                        onChange={handelChangImage}
                      />
                    </>
                  )}
                  {/* <label htmlFor="choseImage" className="droupzo_">
                    {loading ? "Loading" : "Chose Imgee"}
                    <div>
                      {previewUrls.map((url, index) => (
                        <img key={index} src={url} />
                      ))}
                    </div>
                  </label>
                  <input
                    type="file"
                    id="choseImage"
                    className="d-none"
                    onChange={handelChangImage}
                  /> */}
                </Col>

                {/* {previewUrls.map((url, index) => (
                  <img key={index} src={url} />
                ))} */}
                {/* {Object.values(responseLinks).map((item) => {
                  return <p>{item}</p>;
                })} */}
              </Row>
            </div>
            <div className="spacer-40"></div>
            <div className="padd-div text-center mb-2 ">
              <Button
                className="reg-btn book fw-normal"
                onClick={handelBook}
                disabled={isDisabled}
              >
                {t(`Book`)}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalMaintenance;
