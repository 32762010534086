import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import { Button, Col, Form, Row } from "react-bootstrap";
import Post from "../../assets/images/Spa/Post-3.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Dropdown } from "primereact/dropdown";
import axios from "../../axios";
import { array, number, object, string } from "yup";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import TaxInclusive from "../../Components/TaxInclusive.";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import "../../new-pages/hotel-services/HotelServices.scss";
import { Toast } from "primereact/toast";
import { phoneFormat } from "../../constants/api";

function SATAddService() {
  const currency = localStorage.getItem("currency");

  const { t, i18n } = useTranslation();
  const [enableClass, setEnableClass] = useState(true);
  const [selectedTheraphist, setSelectedTheraphist] = useState();
  const [therapists, setTherapists] = useState([]);
  const [timeInput, setTimeInput] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [dateInput, setDateInput] = useState();
  const [service, setService] = useState();
  const [phone, setPhone] = useState();
  const [delivery_charge, setDelivery_charge] = useState();
  const [selectedOptions, setSelectedOptions] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [price, setPrice] = useState(0);
  const [SATService, setSATService] = useState({
    email: "",
    phone: "",
    name: "",
  });
  const timeref = useRef(null);
  const toast = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const id = location.state;
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const lang = localStorage.getItem("lang");
  // const theme = location.state?.theme;
  const new_design = localStorage.getItem("new_design");
  const [currHoursPlusThree, setCurrHoursPlusThree] = useState();

  const theme = new_design == "true";
  const [day_name, setDay_name] = useState();

  const [working_times, setWorking_times] = useState();
  const [isOff, setIsOff] = useState(0);
  const currentTimem = new Date(`${dateInput}T${timeInput}`);
  const dubaiTime = currentTimem.toLocaleString("en-US", {
    timeZone: "Asia/Dubai",
  });
  const dateObj = new Date(dubaiTime);
  const hoursD = dateObj.getHours().toString().padStart(2, "0");
  const minutesD = (dateObj.getMinutes() + 1).toString().padStart(2, "0");
  const time = `${hoursD}:${minutesD}`;

  console.log("hoursD", hoursD);
  const days = [
    { Mon: "Monday" },
    { Tue: "Tuesday" },
    { Wed: "Wednesday" },
    { Thu: "Thursday" },
    { Fri: "Friday" },
    { Sat: "Saturday" },
    { Sun: "Sunday" },
  ];
  //  start_time;
  //  end_time;
  let start_time = "00:00:00";
  let end_time = "23:00:00";
  // let isOff;
  let is_all_day;
  let nameDay;

  let SATSchema = object({
    date: string(),
    time: string(),
    phone: string(),
    email: string().email(),
    service_id: number(),
    salon_id: number(),
    options: array(),

    // address: string().required(),
    // createdOn: date().default(() => new Date()),
  });

  setTimeout(() => {
    setEnableClass(false);
  }, 3000);
  const handleTime = (e) => {
    // const currHoursPlusThree = timeInput;
    e.target.setCustomValidity(``);

    checkCurrentTimeWithMinTimeTheSpa(e.target.value);
    // setTimeInput(e.target.value);
    const newDate = new Date();
    let hours = (newDate.getHours() + 6).toString().padStart(2, "0");

    const minutes = newDate.getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;
    setCurrHoursPlusThree(formattedTime);

    if (
      newDate.toLocaleDateString() == new Date(dateInput).toLocaleDateString()
    ) {
      if (currHoursPlusThree < e.target.value) {
        setTimeInput(e.target.value);
      } else {
        setTimeInput(currHoursPlusThree);
        toast.current.show({
          severity: "warn",
          summary: t("Warning"),
          detail: "The booking time should be after 3 hours at least",
          life: 3000,
        });
      }
    } else {
      setTimeInput(e.target.value);
    }
  };
  const handleDate = (e) => {
    const newDate = new Date();
    if (
      newDate.toLocaleDateString() ==
      new Date(e.target.value).toLocaleDateString()
    ) {
      let hours = (newDate.getHours() + 6).toString().padStart(2, "0");

      const minutes = newDate.getMinutes().toString().padStart(2, "0");
      const formattedTime = `${hours}:${minutes}`;
      timeref.current.setCustomValidity(``);
      setCurrHoursPlusThree(formattedTime);
      setTimeInput(formattedTime);
    }

    if (working_times?.length > 0) {
      const dayOfWeek = new Date(e.target.value).toLocaleString("en-us", {
        weekday: "short",
      });
      nameDay = days.find((dayObj) => dayOfWeek in dayObj)[dayOfWeek];

      // console.log("nameDay********** ", nameDay);
      const dayCur = working_times.filter((time) => {
        if (time.working_day.en_name == nameDay) {
          setDay_name(time?.working_day?.name);
          return time;
        }
      });
      // console.log("dayCur***********", dayCur[0]?.is_off);
      setIsOff(dayCur[0]?.is_off);
      is_all_day = dayCur[0]?.is_all_day;
      if (dayCur[0]?.is_off == 0) {
        start_time = dayCur[0]?.start_time;
        let hours_count =
          dayCur[0]?.hours_count == 24 ? 23 : dayCur[0]?.hours_count;

        const [hours_, minutes_, seconds_] = dayCur[0]?.start_time
          .split(":")
          .map(Number);

        let totalHours = hours_ + hours_count;

        if (totalHours >= 24) {
          totalHours = totalHours - 24;
        }

        end_time = `${String(totalHours).padStart(2, "0")}:${String(
          minutes_
        ).padStart(2, "0")}:${String(seconds_).padStart(2, "0")}`;
      } else {
        start_time = "00:00:00";
        end_time = "23:00:00";
      }

      timeref.current.max = end_time;
      timeref.current.min = start_time;
    }
    setDateInput(e.target.value);
    console.log(" timeref.current.max = end_time", end_time);
    console.log(" timeref.current.min = start_time", start_time);
    console.log(" timeref.current.value", timeref.current.value);
    const current_time = timeref.current.value;

    console.log(" current_time > end_time", current_time > end_time);
    if (current_time > end_time) {
      timeref.current.value = start_time;
      setTimeInput(start_time);
    }
  };

  console.log("Price", price);
  const handleCheckboxChange = (itemId, priceOp) => {
    if (checkedItems.includes(itemId)) {
      setPrice(price - Number(priceOp));
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item !== itemId)
      );
    } else {
      setPrice(price + Number(priceOp));
      setCheckedItems((prevCheckedItems) => [...prevCheckedItems, itemId]);
    }
  };
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const checkValEmail =
    SATService.email.length > 0 && !/\S+@\S+\.\S+/.test(SATService.email);
  console.log("checkemail", !/\S+@\S+\.\S+/.test(SATService.email));
  const checkInputs = () => {
    if (SATService.email.length > 0 && !/\S+@\S+\.\S+/.test(SATService.email)) {
      setErrorEmail("The email must be a valid email address.");
    } else {
      setErrorEmail("");
    }

    if (SATService.phone.length <= 7) {
      setErrorPhone("The phone field is required.");
    } else {
      setErrorPhone("");
    }
  };
  const checkRef = useRef(null);

  const checkCurrentTimeWithMinTimeTheSpa = (cr_time) => {
    // console.log("cr_time", cr_time);
    const min_time_spa = timeref.current.min;
    const max_time_spa = timeref.current.max;
    const current_time_spa = timeref.current.value;

    const currentDate = new Date(dateInput);

    let day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().padStart(2, "0");
    // console.log("hours", hours);
    if (current_time_spa > max_time_spa) {
      // console.log("day", day);
      day = Number(day) + 1;
      const formatDate = `${year}-${month}-${day.toString().padStart(2, "0")}`;
      setDateInput(formatDate);
      toast.current.show({
        severity: "warn",
        summary: t("Warning"),
        detail: "The time has been adjusted as per the salon schedule",
        life: 3000,
      });
      //
      timeref.current.value = timeref.current.min;
    }

    // console.log("formatDate", formatDate);
  };

  useEffect(() => {
    const currentTime = new Date();

    // Format the time as HH:mm (hours and minutes)
    let hours = (currentTime.getHours() + 6).toString().padStart(2, "0");

    const minutes = currentTime.getMinutes().toString().padStart(2, "0");

    const currentDate = new Date();
    // console.log("formattedTime", formattedTime);
    // Format the date as YYYY-MM-DD (required by input type="date")

    const formattedDate = currentDate.toISOString().slice(0, 10);
    let day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().padStart(2, "0");
    // console.log("hours", hours);
    if (hours == "24") {
      hours = "01";
      // console.log("day", day);
      day = day + 1;
    }

    const formattedTime = `${hours}:${minutes}`;
    const formatDate = `${year}-${month}-${day}`;
    setDateInput(formatDate);
    // console.log("formatDate", formatDate);
    setTimeInput(formattedTime);
    setCurrHoursPlusThree(formattedTime);

    console.log("time", timeInput);

    checkCurrentTimeWithMinTimeTheSpa();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSATService((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBook = async () => {
    // const therapist = therapists.find(
    //   (item) => item.name === selectedTheraphist
    // );
    checkInputs();
    if (!checkValEmail && SATService.phone.length > 7) {
      const booked = {
        email: SATService.email,
        phone: SATService.phone,
        date: dateInput,
        time: time,
        salon_id: params.salon_id,
        service_id: params.service_id,
        options: checkedItems,
        redirect_url: `https://${window.location.host}/${room_uuid}/SalonAtHomeCallback`,
        // company_name: company.company_name,
        // company_id: company.company_id,
        // lat: "25.276987",
        // lng: "55.296249",
      };
      console.log("SENDING", booked);
      setIsDisabled(true);
      const anser = await SATSchema.validate(booked);
      axios
        .post(`/${room_uuid}/salon_at_home/book_services`, anser, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          setIsDisabled(false);

          console.log("res", res);
          if (res.data.status) {
            if (res?.data?.data?.payment_url) {
              window.location.replace(res?.data?.data?.payment_url);
            } else {
              navigate(`/${room_uuid}/SpaOrderPlaced`, {
                state: { metadata: res.data.data, salon: true, theme: theme },
              });
            }
          }
        })
        .catch((err) => {
          setIsDisabled(false);
          console.log(err);
        });
      // navigate("/HCOrderPlaced");
    }
  };

  const [title, setTitle] = useState("")

  useEffect(() => {
    const getData = async () => {
      axios
        .get(
          `/${room_uuid}/salon_at_home/${params.salon_uuid}/salone-details/${params.service_id}`,
          {
            headers: {
              lang: lang,
            },
          }
        )
        .then((res) => {
          const data = res.data.data;
          setService(data.service);
          setTitle(data.center_header);
          setDelivery_charge(data.delivery_charge);
          setTherapists(data.service.therapists);
          setWorking_times(data?.salon?.working_times);

          console.log(data);
        });
    };
    getData();
  }, []);
  const show_prices = localStorage.getItem("show_prices");
  // <HeaderInner headerName={"SALON AT HOME"} />
  const handleInvalid = (event, index) => {
    const min = timeref.current.min.split(":");
    const max = timeref.current.max.split(":");
    const hourStartTime = min[0];
    const formatStartTime =
      Number(hourStartTime) > 12
        ? `${hourStartTime - 12}:${min[1]} PM`
        : `${hourStartTime}:${min[1]} AM`;

    const hourEndTime = max[0];
    const formatEndTime =
      Number(hourEndTime) > 12
        ? `${hourEndTime - 12}:${max[1]} PM`
        : `${hourEndTime}:${max[1]} AM`;
    console.log("start_time", formatStartTime);
    console.log("end_time", formatEndTime);
    event.target.setCustomValidity(
      `${t(
        "The time must be between"
      )} ${formatStartTime} ${t("And")} ${formatEndTime}`
    );
    // console.log("timeref value", timeref.current.value);
    // console.log("timeref min", timeref.current.min);
    // console.log("timeref max", timeref.current.max);
  };
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={title} />
      ) : (
        <HeaderInner headerName={title} />
      )}
      <main className="bg-white back-white">
        <Toast ref={toast} position="bottom-center" />

        <div className="add-post-container add-cart mt-2">
          <div className="add-cart-post new spa gray ">
            <div className="add-cart-post-inner">
              <span className="img-pnl navigate">
                <img src={service ? service.image : Post} alt="Post" />
              </span>

              <div
                className="cross-btn-red"
                onClick={() =>
                  navigate(`/${room_uuid}/salon_at_home/${params.salon_uuid}`)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 23 23"
                >
                  <path
                    id="Icon_ionic-ios-close-circle"
                    data-name="Icon ionic-ios-close-circle"
                    d="M14.875,3.375a11.5,11.5,0,1,0,11.5,11.5A11.5,11.5,0,0,0,14.875,3.375Zm2.914,15.663-2.914-2.914-2.914,2.914a.884.884,0,1,1-1.25-1.25l2.914-2.914-2.914-2.914a.884.884,0,0,1,1.25-1.25l2.914,2.914,2.914-2.914a.884.884,0,0,1,1.25,1.25l-2.914,2.914,2.914,2.914a.888.888,0,0,1,0,1.25A.878.878,0,0,1,17.789,19.038Z"
                    transform="translate(-3.375 -3.375)"
                    fill="#f11027"
                  ></path>{" "}
                </svg>
              </div>
              <div
                className="txt-pnl navigate"
              // onClick={handleCartShow}
              // onClick={() => navigate("/SpaAddService")}
              >
                <h2>{service ? service.name : "loading"}</h2>
                <p></p>
                <div
                  className="flex-div align-item-center px-2"
                  style={{
                    justifyContent: "end",
                  }}
                >
                  {/* <h3 style={{ opacity: "0" }}> 30.00</h3> */}
                  <Link
                    onClick={() => navigate(-1)}
                    className="reg-btn service"
                  >
                    {t("Change")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {show_prices == "true" ? (
          <div className="salon-table">
            <table>
              <tbody>
                <tr class="main">
                  <th>{t("Service")} </th>
                  <th> {t("Price")}</th>
                  <th> {t("Add")}</th>
                  <th hidden></th>
                </tr>
                {service?.extras &&
                  service.extras.map((extra) => {
                    return (
                      <>
                        <tr class="detailes">
                          <td colspan="4" class="extra-name">
                            <p className="fw-700">{extra.name}</p>
                          </td>
                        </tr>
                        {extra.options &&
                          extra.options.map((option) => {
                            return (
                              <tr class="detailes">
                                <td>
                                  <p>{option.name}</p>
                                </td>
                                <td className="fw-700">
                                  {currency} {option.price}
                                </td>
                                <td ref={checkRef}>
                                  {" "}
                                  <input
                                    type="checkbox"
                                    class="checkbox-option"
                                    id={`item${option.id}`}
                                    checked={checkedItems.includes(option.id)}
                                    onChange={() => {
                                      handleCheckboxChange(
                                        option.id,
                                        option.price
                                      );
                                      ReactGA.event({
                                        category:
                                          "Select option in Salon at home",
                                        action: "Click",
                                        value: `option: ${option.name}, price: ${option.price}`,
                                      });
                                      // setPrice(price + Number(option.price));
                                    }}
                                    data-gtm-form-interact-field-id="0"
                                  />
                                </td>
                                <td hidden>698</td>
                              </tr>
                            );
                          })}
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="salon-table">
            <table>
              <tbody>
                <tr class="main">
                  <th>{t("Service")} </th>
                  {/* <th> {t("Price")}</th> */}
                  <th> {t("Add")}</th>
                  <th hidden></th>
                </tr>
                {service?.extras &&
                  service.extras.map((extra) => {
                    return (
                      <>
                        {/* <tr class="detailes">
                          <td colspan="4" class="extra-name">
                            <p className="fw-700">{extra.name}</p>
                          </td>
                        </tr> */}
                        {extra.options &&
                          extra.options.map((option) => {
                            return (
                              <tr class="detailes">
                                <td>
                                  <p>{option.name}</p>
                                </td>
                                {/* <td className="fw-700">
                                  {currency} {option.price}
                                </td> */}
                                <td ref={checkRef}>
                                  {" "}
                                  <input
                                    type="checkbox"
                                    class="checkbox-option"
                                    id={`item${option.id}`}
                                    checked={checkedItems.includes(option.id)}
                                    onChange={() => {
                                      handleCheckboxChange(
                                        option.id,
                                        option.price
                                      );
                                      ReactGA.event({
                                        category:
                                          "Select option in Salon at home",
                                        action: "Click",
                                        value: `option: ${option.name}, price: ${option.price}`,
                                      });
                                      // setPrice(price + Number(option.price));
                                    }}
                                    data-gtm-form-interact-field-id="0"
                                  />
                                </td>
                                <td hidden>698</td>
                              </tr>
                            );
                          })}
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
        <div className="duration-div">
          {/*       <p className="title start">Duration</p>
          <Row className="px-3">
            <Col xs="6">
              <p className="time">40 min</p>
            </Col>
            <Col xs="6" className="d-flex justify-content-end">
              <div className="check-div">
               
              </div>
            </Col>
          </Row> */}
          {/*      <Row className="px-3">
            <Col xs="6">
              <p className="time">50 min</p>
            </Col>
            <Col xs="6" className="d-flex justify-content-end">
              <div className="check-div">
                
              </div>
            </Col>
          </Row> */}
          {show_prices == "true" ? (
            <Row className="mt-2">
              <Col xs="6" className="mt-2">
                <p className="title"> {t("Delivery Charges")}</p>
              </Col>
              <Col xs="6" className="d-flex justify-content-end">
                <p className="title">
                  {currency} {service ? delivery_charge : ""}
                </p>
              </Col>
              <Col xs="6" className="mt-2">
                <p className="title"> {t("Total Price")}</p>
              </Col>
              <Col xs="6" className="d-flex justify-content-end">
                <p className="title">
                  {currency} {service ? Number(delivery_charge) + price : ""}
                </p>
              </Col>
            </Row>
          ) : null}
        </div>
        <div className="px-2 spa-inputs">
          <Form>
            {/*  <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                value={"Room number 345"}
                placeholder="Enter Your Room # (Optional)"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control type="text" placeholder="Enter Your Name" />
            </Form.Group> */}

            <Row>
              {/*    <Col xs="6">
                <Form.Group className="mb-3" controlId="formBasicname">
                  <Form.Control
                    type="date"
                    value={dateInput}
                    onChange={handleDate}
                  />
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group className="mb-3" controlId="formBasicname">
                  <Form.Control
                    type="time"
                    value={timeInput}
                    onChange={handleTime}
                  />
                </Form.Group>
              </Col> */}
            </Row>
            <div className="bk-date">
              <Form.Group
                className="mb-3 i-f-small row align-items-center justify-content-start"
                controlId="formBasicname"
              >
                <p className="col-4 m-0 mb-2 ">{t("Booking Date")} </p>
                <Form.Control
                  type="date"
                  value={dateInput}
                  min={new Date().toISOString().slice(0, 10)}
                  onChange={handleDate}
                  placeholder={t("Enter Your Email")}
                  className="col-7 ml-2 date-input dt-input three "
                />
              </Form.Group>
            </div>
            <div className="bk-time mb-4">
              <Form.Group
                className="mb-3 i-f-small row align-items-center justify-content-start "
                controlId="formBasicname"
              >
                <p className="col-4 m-0 mb-2 no-break">{t("Booking Time")}</p>
                <Form.Control
                  type="time"
                  value={timeInput}
                  ref={timeref}
                  min={start_time}
                  max={end_time}
                  onInvalid={handleInvalid}
                  required
                  onChange={handleTime}
                  className="col-7 ml-2 date-input dt-input three "
                />
              </Form.Group>
            </div>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control
                type="emial"
                placeholder={t("Your Email (Optional)")}
                name="email"
                value={SATService.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            {errorEmail != "" && <span className="err_msg">{t(`${errorEmail}`)}</span>}

            {/*    <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Select
                className="form-control"
                aria-label="Default select example"
              >
                <option>Date & Time</option>
              </Form.Select>
            </Form.Group> */}

            <Form.Group className="mb-3 w-100">
              <PhoneInput
                country={phoneFormat}
                value={SATService.phone}
                onChange={(phone) => {
                  setSATService((prev) => ({ ...prev, phone }));
                }}
              />
            </Form.Group>
            {errorPhone != "" && <span className="err_msg">{t(`${errorPhone}`)}</span>}

            {/*   <div className="flex justify-content-center drop-container">
              <Dropdown
                value={selectedTheraphist}
                onChange={(e) => setSelectedTheraphist(e.value)}
                panelClassName="panel-drop therapist"
                options={therapists}
                optionLabel="name"
                optionValue="name"
                placeholder="Therapist Name"
                className="w-full md:w-14rem"
              />
            </div> */}
          </Form>
        </div>
        <div className="spacer-40 mt-3"></div>
        <div className="padd-div text-center ">
          <Button
            className="reg-btn order fw-normal"
            to="/SATOrderPlaced"
            onClick={handleBook}
            disabled={isDisabled || checkedItems.length == 0}
          >
            {t("Book")}
          </Button>
        </div>
        <div className="padd-div text-center">
          <h3 className="text-center">
            <TaxInclusive />
          </h3>
        </div>
        <div className="spacer-40 my-5"></div>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default SATAddService;
