import React, { useEffect, useState } from "react";
import HeaderInner from "./Components/HeaderInner";
import { Accordion, AccordionTab } from "primereact/accordion";
import axios from "./axios";
import { useLocation } from "react-router-dom";
import SpaLoader from "./Components/Loaders/SpaLoader";
import Title from "./new-components/title/Title";
import NewFooter from "./NewFooter";

const GuestDirectory = () => {
  const location = useLocation();

  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const [guest_directory, setGuest_directory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [header_title, setheader_title] = useState("")
  const lang = localStorage.getItem("lang");
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";

  useEffect(() => {
    setLoading(true);
    const getGuestDirectory = async () => {
      // axios.get(`/648ed77e-da78-4987-9b86-de515659fc2c`).then((res) => {
      axios
        .get(`/${room_uuid}/guest-directory`, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          const data = res.data.data;
          setheader_title(data?.header_title)
          setGuest_directory(data.guest_directory);
          console.log("data***", data.guest_directory);
          setLoading(false);
        });
    };
    getGuestDirectory();
  }, []);

  const createDynamicTabs = () => {
    return guest_directory.map((tab, i) => {
      return (
        <AccordionTab key={tab.id} header={tab.title} disabled={tab.disabled}>
          {/* <i class="fa fa-plus" aria-hidden="true"></i> */}
          <div
            dangerouslySetInnerHTML={{
              __html: tab.body,
            }}
          />
        </AccordionTab>
      );
    });
  };
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={header_title} />
      ) : (
        <HeaderInner headerName={header_title} />
      )}
      <main className="home-main bg-white back-white pad-top-0 px-4">
        {loading ? (
          <SpaLoader />
        ) : (
          <Accordion
            multiple
            expandIcon="fa fa-plus"
            collapseIcon="fa fa-minus"
          >
            {createDynamicTabs()}
          </Accordion>
        )}
      </main>
      {theme && <NewFooter />}
    </div>
  );
};

export default GuestDirectory;
