import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Slide1 from "../assets/images/slide-1.png";
import Slide2 from "../assets/images/slide-2.png";
import Slide3 from "../assets/images/slide-3.png";
import Slide4 from "../assets/images/slide-4.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
function Carosle({ className, headerImages, dots, isFirst, isVideo }) {
  const images = [Slide1, Slide2, Slide3, Slide4];
  console.log("isVideo", headerImages);
  const [intervalz, setIntervalz] = useState(isFirst ? 10000 : 3000); //initial state here represents the interval for first image.

  const onChange = (index, item) => {
    setIntervalz(item.props["data-interval"]);
  };
  // console.log("headerImages", headerImages);
  if (isVideo) {
    if (headerImages.length >= 3) {
      const first = headerImages[0];
      const second = headerImages[1];
      const images = headerImages.slice(2);
      headerImages = [second, first, ...images];
    }
  }
  return (
    <div className={className}>
      <Carousel
        className=" noSelect mb-2"
        swipeable={true}
        onChange={onChange}
        autoPlay
        interval={intervalz}
        infiniteLoop={true}
        showIndicators={dots == false ? false : true}
        showArrows={false}
        showThumbs={false}
        emulateTouch={true}
      >
        {headerImages?.map((slide) => {
          if (
            ["mp4", "avi", "mkv", "mov", "webm"].includes(
              slide.image.split(".").pop().toLowerCase()
            )
          ) {
            return (
              <div
                className="carousel-item-custom noSelect"
                data-interval={10000}
              >
                <video
                  width="100%"
                  height="165"
                  autoPlay
                  muted
                  playsinline=""
                  loop
                >
                  <source src={slide.image} type="video/mp4" />
                </video>
              </div>
            );
          }

          return slide.link ? (
            <a href={slide.link}>
              <div
                className="carousel-item-custom noSelect"
                data-interval={3000}
              >
                <img src={slide.image} alt="Slide" />
              </div>
            </a>
          ) : (
            <div className="carousel-item-custom noSelect" data-interval={3000}>
              <img src={slide.image} alt="Slide" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
export default Carosle;
