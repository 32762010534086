import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
const Layout = () => {
  const location = useLocation();
  const [hideFooter, setHideFooter] = useState(false);
  // const location = useLocation()
  console.log("location.pathname", location.pathname);
  useEffect(() => {
    if (
      location.pathname.includes("/RestaurantSubMenu") ||
      location.pathname.includes("/PharmacySubMenu") ||
      location.pathname.includes("/FlowersSubMenu") ||
      location.pathname.includes("/SupermarketSubMenu") ||
      location.pathname.includes("/restaurant-menu/categories") ||
      location.pathname.includes("/restaurants/search") ||
      location.pathname.includes("/restaurant-menu") ||
      location.pathname.includes("/rstauran-menu-categories") ||
      location.pathname.includes("/rstauran-menu-subcategories") ||
      location.pathname.includes("/rstauran-menu-items") ||
      location.pathname.includes("/Checkout-dining") ||
      location.pathname.includes("/qr-scan") ||
      location.pathname.includes("/scan-qr-code") ||
      location.pathname.includes("/not-found") ||
      location.pathname.includes("/food-and-beverages") ||
      location.pathname.includes("/cart") ||
      location.pathname.includes("/search") ||
      location.pathname.includes("/hotel-services") ||
      location.pathname.includes("/qrcode") ||
      location.pathname.includes("/restaurant/combo-meals") ||
      location.search.includes("Restaurant&in_dining")
    ) {
      setHideFooter(true);
    } else {
      setHideFooter(false);
    }

    const new_design = localStorage.getItem("new_design");
    const theme = new_design == "true";

    if (theme) {
      setHideFooter(true);
    }
  }, [location.pathname]);

  return (
    <>
      <Outlet />
      {!hideFooter && <Footer />}
    </>
  );
};

export default Layout;
