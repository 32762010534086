import React, { useEffect, useRef, useState } from "react";
import "./New.scss";
import Banner from "./assets/new_imgs/Group 38650.png";
import Food from "./assets/new_imgs/Mask Group 63.png";
import Sevices from "./assets/new_imgs/Group 38654.png";
import Taxi from "./assets/new_imgs/Group 38651.png";
import Bed from "./assets/new_imgs/Group 38655.png";
import axios from "./axios";
import Rectangle from "./assets/new_imgs/Rectangle 1072.png";

import { Toast } from "primereact/toast";
import NewFooter from "./NewFooter";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ShowHomeMenu } from "./API/HomeMenuAPI";
import BannerHome from "./new-components/banner-home/BannerHome";
import NewLoader from "./new-components/title/Loader/NewLoader";
import { useTranslation } from "react-i18next";
import { ReactTyped } from "react-typed";

import { Zoom, Bounce } from "react-reveal";
import { Button, Modal } from "react-bootstrap";

const NewHome = () => {
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("lang" || "EN");
  const [quickServices, setquickServices] = useState([])
  const [open, setOpen] = useState(false)
  const [serviceId, setServiceId] = useState(null)
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);


  const handleClose = () => {
    setOpen(false)
    setServiceId(null)

  }
  const selectQuickService = (service_id) => {
    setOpen(true)
    setServiceId(service_id)
  }


  const bookQuickService = () => {
    setLoading(true)

    axios
      .post(`/${params.room_uuid}/quick-services/book-service`, { service: serviceId }, {
        headers: {
          lang: lang,
        },
      })
      .then((res) => {
        setLoading(false)

        console.log("respo", res);
        // if (res.data.status == false) {
        toast.current.show({
          severity: "success",
          summary: t("Success"),
          detail: t(`${res.data.data.message}`),
          life: 4000,
        });

        setOpen(false)
        // }

      })
      .catch((err) => {

        console.log(err);
        setLoading(false)

      });
    console.log("serviceId", serviceId);
  }
  let isOneArrayContentItems = false


  const settings = {
    dots: true,
    infinite: isOneArrayContentItems ? false : true,
    speed: 100,
    arrows: false,
    slidesToShow: 1.8,
    leftMode: true,
    slidesToScroll: isOneArrayContentItems ? 0 : 1,
    autoplay: false,
    autoplaySpeed: 2000,
    rtl: lang == "AR" ? true : false,
    initialSlide: lang == "AR" ? 2 : 0,
  };
  const params = useParams();
  const navigate = useNavigate();
  const open_lang = localStorage.getItem("open_lang") || "true";
  const [showServices, setShowServices] = useState(false);
  const [openLang, setOpenLang] = useState(open_lang);
  const [showSelect, setShowSelect] = useState(true);
  const { data, isLoading, error } = ShowHomeMenu(params.room_uuid, lang);
  const [showContent, setShowContent] = useState(true)

  let languages;
  let headerImages;
  let services;
  let logo;
  let spaWellnessService
  let hotelService
  let foodService
  let externalService

  if (!isLoading) {

    if (data.data.status === false) {
      navigate(`/${params.room_uuid}/qr-scan`);
    } else {

      console.log("data", data);
      const menu = data.data.data;
      languages = data?.data?.data?.Languages;
      services = data?.data?.data?.activeServices;
      logo = data?.data?.data?.logo;
      spaWellnessService = data?.data?.data?.activeServices?.find(service => service.id === 4 || service.id === 17);
      hotelService = data?.data?.data?.activeServices?.find(service => service.id === 18 || service.id === 3 || service.id === 5 || service.id === 15 || service.id === 3 || service.id === 7);
      foodService = data?.data?.data?.activeServices?.find(service => service.id === 8 || service.id === 1 || service.id === 2);
      externalService = data?.data?.data?.activeServices?.find(service => service.id === 25
        || service.id === 9
        || service.id === 10
        || service.id === 11
        || service.id === 16
        || service.id === 13
        || service.id === 24
        || service.id === 6
        || service.id === 21
        || service.id === 14
      );


      const arrays = [spaWellnessService, hotelService, foodService, externalService];

      const nonEmptyArrays = arrays.filter(array => array);

      if (nonEmptyArrays.length === 1) {
        isOneArrayContentItems = true
      } else {
        isOneArrayContentItems = false

      }

      headerImages = menu.realvideo
        ? [{ image: menu?.realvideo }, ...menu?.header_images]
        : menu?.header_images;
    }
  }

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    console.log("currentHour", currentHour);

    if (currentHour >= 6 && currentHour < 12) {
      return t("Good Morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      return t("Good Afternoon");
    } else if (currentHour >= 18 && currentHour < 24) {
      return t("Good Evening");
    } else {
      return "Hello";
    }
  };
  const changeLang = (code) => {
    console.log("codecode", code);
    getQuickServices(code)
    setShowSelect(false);
    setShowContent(false);
    localStorage.setItem("open_lang", "false");
    localStorage.setItem("lang", code);
    i18n.changeLanguage(code);
    setTimeout(() => {
      setOpenLang("false");
      setShowContent(true)
    }, 8000);
  };


  let langArray = [];
  languages?.map((language) => {
    if (language.code == "EN") {
      console.log("tes");
      langArray.push({ ...language, name: "English" });
    }
    if (language.code == "AR") {
      langArray.push({ ...language, name: "العربية" });
    }
    if (language.code == "FR") {
      langArray.push({ ...language, name: "Français" });
    }
    if (language.code == "RU") {
      langArray.push({ ...language, name: "Русский" });
    }
    if (language.code == "DE") {
      langArray.push({ ...language, name: "Deutsch" });
    }
    if (language.code == "CH") {
      langArray.push({ ...language, name: "中文" });
    }
    if (language.code == "HI") {
      langArray.push({ ...language, name: "한국어" });
    }
  });

  const getQuickServices = (lang) => {
    axios.get(`/${params.room_uuid}/quick-services`, {
      headers: {
        lang: lang
      }
    }).then((res) => {
      const data = res.data.data;
      // console.log("data", data);
      setquickServices(data.quickServices)
    });
  };
  useEffect(() => {

    getQuickServices(lang);
  }, []);



  return (
    <div className={`new_page ${lang == "AR" && "arabic"}`}>
      {isLoading ? (
        <NewLoader />

      ) : (
        <>
          <div className={`box_lang ${openLang == "true" ? "" : "not_show"}`}>
            <Zoom>
              <div className="logo">
                <img src={logo} alt="" className="adress_logo" />
              </div>
            </Zoom>
            {showSelect ? (
              <>
                <Zoom>
                  <h4>{t("Please select your language to begin")}</h4>
                </Zoom>
                <ul className="languages_list">
                  {langArray.map((item, idx) => {
                    return (
                      <Bounce buttom duration={1000} delay={500} key={idx}>
                        <li
                          onClick={() => changeLang(item.code)}
                          className={item.code == lang ? "lang_active" : ""}
                        >
                          {item.name}
                        </li>
                      </Bounce>
                    );
                  })}
                </ul>
              </>
            ) : (
              <>
                <Bounce buttom duration={1000} delay={200}>
                  <h4 className="welcome_title">{getGreeting()}</h4>
                </Bounce>
                <Zoom buttom duration={1000} delay={800}>
                  <p className="welocome">
                    {" "}
                    <ReactTyped
                      strings={[
                        t(
                          "Welcome! Your comfort and satisfaction are our top priority. Enjoy your stay!"
                        ),
                      ]}
                      typeSpeed={50}
                    />
                  </p>
                </Zoom>
              </>
            )}
          </div>
          {
            showContent &&
            <>
              <BannerHome
                className={"dot-bar less-margin small-dots"}
                isFirst={true}
                isVideo={true}
                headerImages={headerImages}
                bannerWithAdditional={true}
              />
              <div className="quick_services">
                {showServices ? (
                  <div className="services_box">
                    {
                      quickServices?.map((item) => {
                        return (
                          <span key={item.id} onClick={() => {

                            if (item.name.includes("Feedback / Complaint") || item.name.includes("Hotel Review") || item.name.includes("Review / Complaint")) {
                              navigate(`/${params.room_uuid}/feedback`)
                            } else {
                              selectQuickService(item.id)
                            }
                          }
                          }
                          >
                            {t(`${item.name}`)}


                          </span>

                        )
                      })
                    }

                  </div>
                ) : (
                  <button onClick={(e) => setShowServices(true)}>
                    {t("Quick Services")}
                  </button>
                )}
              </div>
              <div className="our_offering">
                <h3>{t("Explore Our Offerings")}</h3>

                {
                  isOneArrayContentItems ?
                    <div className="box_slider">
                      {
                        foodService &&
                        <div
                          className="image_carousel"
                          onClick={() =>
                            navigate(`/${params.room_uuid}/food-and-beverages`, {
                              state: { metadata: services },
                            })
                          }
                        >
                          <img src={Food} alt="" />
                          <img src={Rectangle} alt="" className="img_overlay" />
                          <h6 className="img_title">{t("Food & Beverages")}</h6>
                        </div>
                      }

                      {
                        hotelService &&
                        <Link
                          to={`/${params.room_uuid}/hotel-services`}
                          state={{ metadata: services }}
                          className="image_carousel"
                        >
                          <img src={Sevices} alt="" />
                          <img src={Rectangle} alt="" className="img_overlay" />
                          <h6 className="img_title">{t("Hotel Services")}</h6>
                        </Link>
                      }
                      {spaWellnessService &&
                        <Link
                          to={`/${params.room_uuid}/spa-wellness`}
                          state={{ metadata: services }}
                          className="image_carousel"
                        >
                          <img src={Bed} alt="" />
                          <img src={Rectangle} alt="" className="img_overlay" />
                          <h6 className="img_title">{t("Spa & Wellness")}</h6>
                        </Link>
                      }
                      {
                        externalService &&

                        <Link
                          to={`/${params.room_uuid}/external-services`}
                          state={{ metadata: services }}
                          className="image_carousel"
                        >
                          <img src={Taxi} alt="" />
                          <img src={Rectangle} alt="" className="img_overlay" />
                          <h6 className="img_title">{t("External Services")}</h6>
                        </Link>
                      }
                    </div> :
                    <Slider {...settings}>
                      {
                        foodService &&
                        <div
                          className="image_carousel"
                          onClick={() =>
                            navigate(`/${params.room_uuid}/food-and-beverages`, {
                              state: { metadata: services },
                            })
                          }
                        >
                          <img src={Food} alt="" />
                          <img src={Rectangle} alt="" className="img_overlay" />
                          <h6 className="img_title">{t("Food & Beverages")}</h6>
                        </div>
                      }

                      {
                        hotelService &&
                        <Link
                          to={`/${params.room_uuid}/hotel-services`}
                          state={{ metadata: services }}
                          className="image_carousel"
                        >
                          <img src={Sevices} alt="" />
                          <img src={Rectangle} alt="" className="img_overlay" />
                          <h6 className="img_title">{t("Hotel Services")}</h6>
                        </Link>
                      }
                      {spaWellnessService &&
                        <Link
                          to={`/${params.room_uuid}/spa-wellness`}
                          state={{ metadata: services }}
                          className="image_carousel"
                        >
                          <img src={Bed} alt="" />
                          <img src={Rectangle} alt="" className="img_overlay" />
                          <h6 className="img_title">{t("Spa & Wellness")}</h6>
                        </Link>
                      }
                      {
                        externalService &&

                        <Link
                          to={`/${params.room_uuid}/external-services`}
                          state={{ metadata: services }}
                          className="image_carousel"
                        >
                          <img src={Taxi} alt="" />
                          <img src={Rectangle} alt="" className="img_overlay" />
                          <h6 className="img_title">{t("External Services")}</h6>
                        </Link>
                      }

                    </Slider>
                }
              </div>
              <div className="language_box">
                <div
                  className="btn_lang"
                  onClick={() => {
                    setOpenLang("true");
                    setShowSelect(true);
                  }}
                >
                  {lang}
                </div>
              </div>
              <NewFooter />
            </>
          }

        </>
      )}
      <Toast ref={toast} position="top-right" />

      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        className={`food-modal rate_modal q_services_modal p-0`}
        style={{
          minHeight: "150px",
          padding: "0px !important",
          display: "flex !important",
          alignItems: "center !important",
          paddingRight: "0 !important",
        }}
      >
        <Modal.Body style={{ padding: "20px 10px", height: "auto" }}>
          <h5>{t("Are you sure you want to submit the request?")} </h5>

          <div className="servi_btns">
            <Button disabled={loading} className="no" onClick={() => setOpen(false)}>No</Button>
            <Button disabled={loading} className="yes" onClick={bookQuickService}>Yes</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>

  );
};

export default NewHome;
