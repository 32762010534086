import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PaymentOptionList from "../../Components/PaymentOptionList";
import { Form, Button, Modal, Toast } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import "./Cart.scss";
import axios from "../../axios";
import CartItem from "../../Components/CartItem";
import { ShowCheckout } from "../../API/CheckoutAPI";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import ReactGA from "react-ga4";
import NewLoader from "../../new-components/title/Loader/NewLoader";
import AdditionalItems from "../../Pages/Checkout/AdditionalItems";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import { phoneFormat } from "../../constants/api";

function Cart() {
  const [loadingPromo, setLoadingPromo] = useState(false);
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [flowers_delivery_time, setFlowers_delivery_time] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openMin, setOpenMin] = useState(false);
  const [openAdditional, setOpenAdditional] = useState(false);
  const [is_guest, setIs_guest] = useState(0);
  const [umbrella_room_number, setUmbrella_room_number] = useState("");
  const [family_name, setFamily_name] = useState("");

  const handleCloseMin = () => {
    setOpenMin(false);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const rawPath = location.pathname;
  const { t, i18n } = useTranslation();
  const metadata = location.state?.metadata;
  const currency = localStorage.getItem("currency");
  useEffect(() => {
    if (metadata != undefined) {
      toast.error(metadata, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, []);

  const room_uuid = rawPath.split("/")[1];
  let roomNumber = "";
  let addItemsLink;
  let isUmbrella = 0;
  let cartItems = [];
  let totalPrice = 0;
  let check;
  let required;
  let methodPaymant = [];
  let resturan_prie = 0;
  let resturan_min_price = 0;
  let flower_prie = 0;
  let flower_min_price = 0;
  let pharmacy_prie = 0;
  let pharmacy_min_price = 0;
  let supermarket_prie = 0;
  let supermarket_min_price = 0;
  let res_name;
  let ph_name;
  let fl_name;
  let su_name;
  let isPharmacy = false;
  let pharmacyId;
  let additional_items = [];
  let order_business;

  let florest_check = false;
  let detailed_address;
  let municipality;
  let deliveryCharge;
  let shippingFee;

  let discount;
  let show_checkout_fields;
  let fl_disclaimer;

  const lang = localStorage.getItem("lang");
  console.log("lang*****", lang);

  const { data, isLoading, error } = ShowCheckout(room_uuid, lang);

  if (!isLoading) {
    if (data.data.status === false) {
    } else {
      check = data.data.data;
      cartItems = check.order;
      roomNumber = check.roomNumber;
      addItemsLink = check.addItemsLink;
      required = check.required;
      discount = check.discount;
      municipality = check["municipality-tax"];
      deliveryCharge = check.deliveryCharge;
      shippingFee = check.shippingFee;

      methodPaymant = check.payment_method;
      show_checkout_fields = check.show_checkout_fields;
      detailed_address = check.room.detailed_address;
      isUmbrella = check.room.isUmbrella;
      totalPrice = check["total-price"];
      check?.order.map((item) => {
        if (item.order_type == "restaurant_order") {
          resturan_min_price = item["min-amount"];
          res_name = item.name;
          order_business = item.order_business;
          item?.items.map((it) => {
            resturan_prie = resturan_prie + it?.price;

            if (it?.additional_items.length > 0) {
              additional_items.push(it);
            }
          });
        }
        if (item.order_type == "florist_order") {
          florest_check = true;
          flower_min_price = item["min-amount"];
          fl_name = item.name;
          fl_disclaimer = item?.disclaimer;
          item?.items.map((it) => {
            flower_prie = flower_prie + it?.price;
          });
        }
        if (item.order_type == "pharmacy_order") {
          isPharmacy = true
          pharmacyId = item?.order_business.id
          ph_name = item.name;
          pharmacy_min_price = item["min-amount"];
          item?.items.map((it) => {
            pharmacy_prie = pharmacy_prie + it?.price;
          });
        }
        if (item.order_type == "supermarket_order") {
          supermarket_min_price = item["min-amount"];
          su_name = item.name;
          item?.items.map((it) => {
            supermarket_prie = supermarket_prie + it?.price;
          });
        }
      });
      // roomNumber = roomNumber.replace("Room Number ", "");
    }
  }
  const [selected, setSelected] = useState(methodPaymant[0]?.payment_method);

  const [order, setOrder] = useState({
    name: "",
    email: "",
    phone: "",
    note: "",
  });

  useEffect(() => {
    if (isUmbrella == 1) {
      setIs_guest(1);
    } else {
      setIs_guest(0);
    }
  }, [isUmbrella]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrder((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [flowers_delivery_timeError, setFlowers_delivery_timeError] =
    useState("");
  const checkValEmail =
    order.email.length >= 0 && !/\S+@\S+\.\S+/.test(order.email);
  const checkInputs = () => {
    if (order.name == "") {
      setName("Name is a required field");
    } else {
      setName("");
    }
    if (florest_check && flowers_delivery_time == "") {
      setFlowers_delivery_timeError(
        "Flowers delivery time is a required field"
      );
    } else {
      setFlowers_delivery_timeError("");
    }
    if (order.name == "") {
      setName("Name is a required field");
    } else {
      setName("");
    }
    if (checkValEmail) {
      setEmail("The email must be a valid email address.");
    } else {
      setEmail("");
    }
    if (order.phone == "") {
      setPhone("Phone is a required field");
    } else {
      setPhone("");
    }
  };

  const isUmbrellaReq = () => {
    let ordered;

    if (selected == "pay_online") {
      ordered = {
        payment_method: selected,
        is_guest: is_guest,
        umbrella_room_number: umbrella_room_number,
        family_name: family_name,
        redirect_url: `https://${window.location.host}/${room_uuid}/CheckoutCallback`,
      };
    } else {
      ordered = {
        payment_method: selected,
        is_guest: is_guest,
        umbrella_room_number: umbrella_room_number,
        family_name: family_name,
      };
    }

    setIsDisabled(true);

    axios
      .post(`/${room_uuid}/orders/checkout`, ordered, {
        headers: {
          lang: lang,
        },
      })
      .then((res) => {
        setIsDisabled(false);
        ReactGA.event({
          category: "Order from checkout page",
          action: "Click",
          value: `type: umbrella_room`,
        });
        // console.log("rsjsskes", res?.data?.data?.payment_url);

        if (res.data.status) {
          if (res?.data?.data?.payment_url) {
            window.location.replace(res?.data?.data?.payment_url);
          } else {
            navigate(`/${room_uuid}/food-and-beverages/order`, {
              state: { metadata: res.data.data },
            });
          }
        } else if (res.data?.status == false) {
          toast.error(res.data?.message, {
            className: 'custom-toast',
            bodyClassName: 'custom-toast',
            progressClassName: 'custom-toast',
          });
        }
      })
      .catch((err) => {
        setIsDisabled(false);
        console.log(err);
        toast.error("err.message");
      });
  };

  const POSTReq = (type) => {
    let ordered;
    if (type == true) {
      if (selected == "pay_online") {
        ordered = {
          payment_method: selected,
          name: order.name,
          email: order.email,
          phone: order.phone,
          redirect_url: `https://${window.location.host}/${room_uuid}/CheckoutCallback`,
          note: order.note,
          flowers_delivery_time: flowers_delivery_time,
        };
      } else {
        ordered = {
          payment_method: selected,
          name: order.name,
          email: order.email,
          phone: order.phone,
          note: order.note,
          flowers_delivery_time: flowers_delivery_time,
        };
      }
    } else {
      ordered = {
        payment_method: selected,
        flowers_delivery_time: flowers_delivery_time,
      };
      if (order.email != "") {
        ordered.email = order.email;
      }
      if (order.name != "") {
        ordered.name = order.name;
      }
      if (order.phone != "") {
        ordered.phone = order.phone;
      }
      if (order.note != "") {
        ordered.note = order.note;
      }
    }
    if (isUmbrella == 1) {
      // is_guest
      ordered.is_guest = is_guest;
    }

    setIsDisabled(true);

    axios
      .post(`/${room_uuid}/orders/checkout`, ordered, {
        headers: {
          lang: lang,
        },
      })
      .then((res) => {
        setIsDisabled(false);
        ReactGA.event({
          category: "Order from checkout page",
          action: "Click",
          value: ``,
        });

        if (res.data.status) {
          if (res?.data?.data?.payment_url) {
            window.location.replace(res?.data?.data?.payment_url);
          } else {
            navigate(`/${room_uuid}/food-and-beverages/order`, {
              state: { metadata: res.data.data },
            });
          }
        } else if (res.data?.status == false) {
          toast.error(res.data?.message, {
            className: 'custom-toast',
            bodyClassName: 'custom-toast',
            progressClassName: 'custom-toast',
          });
          queryClient.invalidateQueries(`checkout`);
        }
      })
      .catch((err) => {
        setIsDisabled(false);
        toast.error(err.message);
        toast.error("err.message");

        console.log(err);
      });
  };
  const checkPrice = () => {
    if (Number(flower_min_price) > flower_prie) {
      setOpenMin(true);
      return true;
    }
    if (Number(resturan_min_price) > resturan_prie) {
      setOpenMin(true);
      return true;
    }
    if (Number(supermarket_min_price) > supermarket_prie) {
      setOpenMin(true);
      return true;
    }
    if (Number(pharmacy_min_price) > pharmacy_prie) {
      setOpenMin(true);
      return true;
    }
  };
  const checkFlor = florest_check && flowers_delivery_time != "";
  const handleCheckout = async (e) => {
    e.preventDefault();

    if (isChecked) {



      if (show_checkout_fields == 0) {
        setIsDisabled(true);
        axios
          .post(
            `/${room_uuid}/orders/checkout`,
            {
              note: order.note,
              name: order.name,
            },
            {
              headers: {
                lang: lang,
              },
            }
          )
          .then((res) => {
            setIsDisabled(false);
            ReactGA.event({
              category: "Order from checkout page",
              action: "Click",
              value: ``,
            });
            // console.log("rsjsskes", res?.data?.data?.payment_url);
            if (res.data.status) {
              if (res?.data?.data?.payment_url) {
                window.location.replace(res?.data?.data?.payment_url);
              } else {
                navigate(`/${room_uuid}/food-and-beverages/order`, {
                  state: { metadata: res.data.data },
                });
              }
            } else if (res.data?.status == false) {
              toast.error(res.data?.message);
              queryClient.invalidateQueries(`checkout-${lang}`);
            }
          })
          .catch((err) => {
            setIsDisabled(false);
            toast.error(err.message);
            toast.error("err.message");
            console.log(err);
          });
      } else {
        if (!checkPrice()) {
          if (isUmbrella == 1 && is_guest == 1) {
            isUmbrellaReq();
          } else if (selected == "pay_online" || required == true) {
            checkInputs();

            if (!checkValEmail && order.name != "" && order.phone != "") {
              if (florest_check) {
                if (flowers_delivery_time != "") {
                  POSTReq(true);
                }
              } else {
                POSTReq(true);
              }
            }
          } else {
            POSTReq(false);
          }
        }
      }
    } else {
      toast.error("Please agree to the terms and conditions", {
        className: 'custom-toast',
        bodyClassName: 'custom-toast',
        progressClassName: 'custom-toast',
      })
    }
  };

  const has_extra_services = localStorage.getItem("has_extra_services");
  console.log("has_extra_services", has_extra_services);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const sendPromoCode = () => {
    setLoadingPromo(true);
    axios
      .post(`/${room_uuid}/orders/apply-code`, { code })
      .then((res) => {
        console.log("resp", res);
        if (res.data.status) {
          toast.success(res.data.message, {
            className: 'custom-toast',
            bodyClassName: 'custom-toast',
            progressClassName: 'custom-toast',
          });
          queryClient.invalidateQueries(`checkout-${lang}`);
        } else if (res.data.status == false) {
          toast.error(res.data.message[0]);
        }
        ReactGA.event({
          category: "Send Promo code",
          action: "Click",
          value: `promo code: ${code}`,
        });
        setLoadingPromo(false);
      })
      .catch((err) => {
        console.log("eer", err);
        setLoadingPromo(false);
      });
  };

  console.log("additional_items", additional_items);
  const [issExpress, setIsExpress] = useState(false)


  const handleExpressServices = (e) => {
    if (required) {
      if (order.name != "" && order.email != "" && order.phone != "") {
        axios
          .post(`/${room_uuid}/pharmacy/toggle-express`, {
            pharmacy_id: pharmacyId,
            express_service: e.target.checked ? 1 : 0,
            name: order.name,
            email: order.email,
            phone: order.phone
          }, {
            headers: {
              lang: lang,
            },
          })
          .then((res) => {
            // shippingFee = res.data.data.price.shippingFee
            queryClient.invalidateQueries(`checkout-${lang}`);

            console.log("rsjsskes", res.data.data.price);
          })
          .catch((err) => {
            console.log(err);
          });

        setIsExpress(e.target.checked)
      } else {
        toast.error("Please fill the required fields below", {
          className: 'custom-toast',
          bodyClassName: 'custom-toast',
          progressClassName: 'custom-toast',
        })
      }

    } else {
      axios
        .post(`/${room_uuid}/pharmacy/toggle-express`, {
          pharmacy_id: pharmacyId,
          express_service: e.target.checked ? 1 : 0,
          name: order.name,
          email: order.email,
          phone: order.phone
        }, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          // shippingFee = res.data.data.price.shippingFee
          queryClient.invalidateQueries(`checkout-${lang}`);

          console.log("rsjsskes", res.data.data.price);
        })
        .catch((err) => {
          console.log(err);
        });

      setIsExpress(e.target.checked)
    }



  }

  useEffect(() => {
    console.log("data?.data?.data?.express_service", data?.data?.data?.express_service);
    setIsExpress(data?.data?.data?.express_service)
  }, [data])
  return (
    <div className="food pb-4">
      <Title title={"Checkout"} />
      <main className=" v2">
        <Toast ref={toast} position="bottom-center" />
        {isLoading ? (
          <NewLoader />
        ) : (
          <>
            {cartItems?.map((order) => {
              return order.items.map((item) => {
                return (
                  <CartItem
                    item={item}
                    v2={true}
                    order_type={order.order_type}
                    url_decrease={order.url_decrease}
                    url_increase={order.url_increase}
                    url_remove={order.url_remove}
                  />
                );
              });
            })}

            {cartItems?.map((order) => {
              return order?.combo_meals.map((item) => {
                console.log("cartItems", item);
                return (
                  <CartItem
                    item={item}
                    v2={true}
                    order_type={order.order_type}
                    url_decrease={order.url_meal_decrease}
                    url_increase={order.url_meal_increase}
                    url_remove={order.url_meal_remove}
                  />
                );
              });
            })}
          </>
        )}
        {cartItems?.length > 0 && addItemsLink != null ? (
          <div className="d-flex justify-content-center mt-2">
            <Link
              to={`/${addItemsLink.replace(
                "/restaurants?b=kit",
                "/restaurant/categories/10"
              )}`}
              className="reg-btn order-btn btn btn-primary"
              onClick={() => {
                ReactGA.event({
                  category: "Click on button Add More Items in checkout page",
                  action: "Click",
                  value: `Add More Items`,
                });
              }}
            >
              {t("Add More Items")}
            </Link>
          </div>
        ) : null}
        {show_checkout_fields == 1 && (
          <div className="code-parent mb-4">
            <div class="code-section">
              <div class="left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.375"
                  height="15.5"
                  viewBox="0 0 19.375 15.5"
                >
                  <path
                    id="Icon_awesome-tags"
                    data-name="Icon awesome-tags"
                    d="M15.074,6.84,8.66.426A1.453,1.453,0,0,0,7.632,0H1.453A1.453,1.453,0,0,0,0,1.453V7.632A1.453,1.453,0,0,0,.426,8.66L6.84,15.074a1.453,1.453,0,0,0,2.055,0L15.074,8.9a1.453,1.453,0,0,0,0-2.055Zm-11.684-2A1.453,1.453,0,1,1,4.844,3.391,1.453,1.453,0,0,1,3.391,4.844ZM18.949,8.9,12.77,15.074a1.453,1.453,0,0,1-2.055,0l-.011-.011,5.269-5.269a2.725,2.725,0,0,0,0-3.853L10.033,0h1.475a1.453,1.453,0,0,1,1.028.426L18.949,6.84a1.453,1.453,0,0,1,0,2.055Z"
                    fill="#006390"
                  ></path>
                </svg>
                <p>{t("Promo Code")}</p>
              </div>
              <div class="middel">
                <input
                  type="text"
                  name="code"
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div class="right">
                <Button disabled={loadingPromo} onClick={sendPromoCode}>
                  {t("Apply")}{" "}
                </Button>
              </div>
            </div>
          </div>
        )}
        {/* {isPharmacy && (
          <div class="col-12">
            <div class="mb-3 form-group laundray_check">
              <input
                class="accented"
                type="checkbox"
                id="is_express"
                checked={issExpress}
                name="is_express"
                onChange={handleExpressServices}
              />

              <label class="form-check-label ms-1" for="is_express">
                Pharmacy instant delivery (within 30 mins)
              </label>
            </div>
          </div>
        )} */}

        {discount != "0.0" && (
          <div className="full-heading discount flex m-0 mb-3">
            <h2> {t("Discount")}</h2>
            <h2>
              {currency} {discount}
            </h2>
          </div>
        )}
        {deliveryCharge != "hidden" && (
          <div className="full-heading discount flex m-0 mb-3">
            <h2> {t("Delivery Charge")}</h2>
            <h2>
              {currency} {deliveryCharge}
            </h2>
          </div>
        )}
        {shippingFee != "hidden" && (
          <div className="full-heading discount flex m-0 mb-3">
            <h2> {t("Shipping Fee")}</h2>
            <h2>
              {currency} {shippingFee}
            </h2>
          </div>
        )}
        {municipality != "hidden" && (
          <div className="full-heading discount flex m-0 mb-3">
            <h2> {t("Municipality Tax")}</h2>
            <h2>
              {currency} {municipality}
            </h2>
          </div>
        )}
        {show_checkout_fields == 1 && (
          <div className="full-heading total flex mb-5">
            <h2> {t("Order Total")}</h2>
            <h2>
              {currency} {totalPrice}
            </h2>
          </div>
        )}
        <Form className="px-3 rounded-form" onSubmit={handleCheckout}>
          {show_checkout_fields == 1 ? (
            <>
              {isUmbrella == 1 ? (
                <div className="guest">
                  <p>{t("Are you a hotel guest?")}</p>
                  <div className="guest_container">
                    <div className="padd-div d-flex  ">
                      <Form.Check
                        type="radio"
                        label=""
                        checked={is_guest == 1}
                        onClick={() => setIs_guest(1)}
                      />
                      <span>{t("Yes")}</span>
                    </div>
                    <div className="padd-div d-flex ">
                      <Form.Check
                        type="radio"
                        label=""
                        checked={is_guest == 0}
                        onClick={() => setIs_guest(0)}
                      />
                      <span>{t("No")}</span>
                    </div>
                  </div>
                </div>
              ) : null}

              {is_guest == 1 && isUmbrella == 1 ? (
                <>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="number"
                      placeholder={t("Room Number")}
                      value={umbrella_room_number}
                      required={is_guest == 1}
                      onChange={(e) => setUmbrella_room_number(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder={t("Family Name")}
                      value={family_name}
                      required={is_guest == 1}
                      onChange={(e) => setFamily_name(e.target.value)}
                    />
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      disabled={true}
                      value={roomNumber}
                    />
                  </Form.Group>
                  {detailed_address != null && (
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={detailed_address}
                      // placeholder="Enter Your Room # (Optional)"
                      />
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3" controlId="formBasicname">
                    <Form.Control
                      type="text"
                      placeholder={t(
                        `Your Name ${selected == "pay_online" || required == true
                          ? ""
                          : "(Optional)"
                        }`
                      )}
                      name="name"
                      value={order.name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  {name && <span className="err_msg"> {t(`${name}`)}</span>}

                  <Form.Group className="mb-3" controlId="formBasicname">
                    <Form.Control
                      type="emial"
                      placeholder={t(
                        `Your Email ${selected == "pay_online" || required == true
                          ? ""
                          : "(Optional)"
                        }`
                      )}
                      name="email"
                      value={order.email}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  {email && <span className="err_msg">{t(`${email}`)} </span>}

                  {/*    <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Select
                className="form-control"
                aria-label="Default select example"
              >
                <option>Date & Time</option>
              </Form.Select>
            </Form.Group> */}

                  <Form.Group className="mb-3 w-100">
                    <PhoneInput
                      country={phoneFormat}
                      value={order.phone}
                      onChange={(phone) => {
                        setOrder((prev) => ({ ...prev, phone }));
                      }}
                    />
                  </Form.Group>
                  {phone && <span className="err_msg">{t(`${phone}`)} </span>}
                  {florest_check && (
                    <>
                      <Form.Group className="mb-3" controlId="formBasicname">
                        <select
                          className="form-control"
                          placeholder=""
                          // required={true}
                          value={flowers_delivery_time}
                          onChange={(e) => {
                            setFlowers_delivery_time(e.target.value);
                            ReactGA.event({
                              category:
                                "Select Flowers_delivery_time in checkout page",
                              action: "Click",
                              value: `value: ${e.target.value}`,
                            });
                          }}
                        >
                          <option value="">Flowers Delivery Time</option>
                          <option value="10:00 AM - 12:00 PM">
                            10:00 AM - 12:00 PM
                          </option>
                          <option value="12:00 PM - 02:00 PM">
                            12:00 PM - 02:00 PM
                          </option>
                          <option value="02:00 PM - 04:00 PM">
                            02:00 PM - 04:00 PM
                          </option>
                          <option value="04:00 PM - 06:00 PM">
                            04:00 PM - 06:00 PM
                          </option>
                        </select>
                      </Form.Group>
                      {flowers_delivery_timeError && (
                        <span className="err_msg">
                          {t(`${flowers_delivery_timeError}`)}
                        </span>
                      )}
                    </>
                  )}
                  <Form.Group className="mb-3" controlId="formBasicname">
                    <Form.Control
                      type="text"
                      placeholder={t("Note/Special Request (Optional)")}
                      name="note"
                      value={order.note}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </>
              )}

              <div className="full-heading payment flex">
                <h2>{t("Payment Options")}</h2>
              </div>
              <PaymentOptionList
                selected={selected}
                setSelected={setSelected}
                methods={methodPaymant}
              />
              <div className="padd-div d-flex agree my-4">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onClick={() => {
                    setIsChecked((prev) => !prev);
                    ReactGA.event({
                      category: "click on checkbox in checkout page ",
                      action: "Click",
                      value: `I agree to Terms & Conditions: ${isChecked}`,
                    });
                  }}
                />
                <a
                  href="https://thedigitalhotelier.com/terms-and-conditions/"
                  target="_blank"
                >
                  {t("I agree to Terms & Conditions")}
                </a>
              </div>
            </>
          ) : (
            <>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  disabled={true}
                  value={`${roomNumber}`}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicname">
                <Form.Control
                  type="text"
                  placeholder={t(`Your Name (Optional) `)}
                  name="name"
                  value={order.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicname">
                <Form.Control
                  type="text"
                  placeholder={t("Note/Special Request (Optional)")}
                  name="note"
                  value={order.note}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </>
          )}

          <div className="padd-div text-center">
            <Button
              type="submit"
              className="reg-btn order-btn"
              // onClick={}
              disabled={isDisabled || cartItems.length == 0}
            // to="/RestaurantOrderPlaced"
            >
              {t("Order")}
            </Button>
          </div>
          {fl_disclaimer && (
            <div
              className="mx-3 text-center"
              dangerouslySetInnerHTML={{
                __html: fl_disclaimer,
              }}
            />
          )}
        </Form>

        {/* <div className="spacer-40 my-5"></div> */}
      </main>
      <NewFooter />
      <AdditionalItems
        setOpenAdditional={setOpenAdditional}
        openAdditional={openAdditional}
        additional_items={additional_items}
        order_business={order_business}
        theme={true}
      />
      <Modal
        show={openMin}
        onHide={handleCloseMin}
        className={` `}
        style={{ minHeight: "150px", height: "auto" }}
      >
        <Modal.Body
          style={{
            padding: "20px",
            height: "auto",
          }}
        >
          {resturan_min_price > resturan_prie && (
            <>
              <p className=" ">
                {`${t("To proceed with you order for")} ${res_name} ${t("you need to reach the amount of")} ${currency} ${resturan_min_price} ${t("and you already have")} ${currency} ${resturan_prie}`}
              </p>
              <Link
                to={`/${room_uuid}/restaurant/categories/10`}
                className="reg-btn check_m"
              >
                Go to restaurant
              </Link>
              <hr />
            </>
          )}

          {flower_min_price > flower_prie && (
            <>
              <p className=" ">
                {`${t("To proceed with you order for")} ${fl_name} ${t("you need to reach the amount of")} ${currency} ${flower_min_price} ${t("and you already have")} ${currency} ${flower_prie}`}
              </p>
              <Link to={`/${room_uuid}/florists`} className="reg-btn check_m ">
                {t("Go to florist")}
              </Link>
              <hr />
            </>
          )}
          {pharmacy_min_price > pharmacy_prie && (
            <>
              <p className=" ">
                {`${t("To proceed with you order for")} ${ph_name} ${t("you need to reach the amount of")} ${currency} ${pharmacy_min_price} ${t("and you already have")} ${currency} ${pharmacy_prie}`}
              </p>
              <Link to={`/${room_uuid}/pharmacies`} className="reg-btn check_m">
                {t("Go to pharmacy")}
              </Link>
              <hr />
            </>
          )}

          {supermarket_min_price > supermarket_prie && (
            <>
              <p className=" ">
                {`${t("To proceed with you order for")} ${su_name} ${t("you need to reach the amount of")} ${currency} ${supermarket_min_price} ${t("and you already have")} ${currency} ${supermarket_prie}`}
              </p>
              <Link
                to={`/${room_uuid}/supermarkets`}
                className="reg-btn check_m"
              >
                {t("Go to subermarket")}
              </Link>
              <hr />
            </>
          )}
          <div>
            <Button
              onClick={handleCloseMin}
              className="reg-btn v2 service"
              style={{ textAlign: "center", padding: "8.5px 15px !important" }}
            >
              {t(`OK`)}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Cart;
