import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import HeaderInner from "../../Components/HeaderInner";
import Post1 from "../../assets/images/In-Room-Dining/Restaurant-2.png";
import AddCartPost from "../../Components/AddCartPost";
import { Button, Modal } from "react-bootstrap";
import ViewCartModal from "../../Components/ViewCartModal";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import useDraggableScroll from "use-draggable-scroll";
import Carosle from "../../Components/Carosle";
import { useCartStore } from "../../store/useStore";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import ErrorPage from "../../Components/ErrorPage";
import { RestaurantSubMenuAPI } from "../../API/In-Room-DiningAPI";
import ViewCart from "../../Components/ViewCart";
import { useInfiniteQuery } from "react-query";
import Search from "../../Components/Search";
import HeaderInnerInDining from "../../Components/HeaderInnerInDining";
import BannerHome from "../../new-components/banner-home/BannerHome";
// : r_uuid /: c_id
function InDiningRestaurantSubMenu() {
  const params = useParams();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(params.c_id);
  // const [headerName, setHeaderName] = useState();
  // const [images, setImages] = useState([]);
  // const [categories, setCategories] = useState([]);
  // const [error, setError] = useState("");
  const [total, setTotal] = useState(0);
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  const { cart } = useCartStore((state) => ({
    cart: state.cart,
  }));
  const lang = localStorage.getItem("lang");
  const { cartCount, setCartCount } = useCartStore((state) => ({
    cartCount: state.cartCount,
    setCartCount: state.setCartCount,
  }));

  console.log();
  const navigate = useNavigate();
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  // const [restaurantItems, setRestaurantItems] = useState();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const r_uuid = rawPath.split("RestaurantSubMenu/")[1];
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const scrollToItem = (index) => {
    const itemWidth = ref.current.children[0].offsetWidth;
    const scrollPosition = index * itemWidth;

    ref.current.scrollTo({
      left: scrollPosition,
      behavior: "smooth",
    });
  };

  // console.log('r_uuid', params.r_uuid);
  // console.log('c_id', selected);
  useEffect(() => {
    let tempTotal = 0;
    cart.map((item) => {
      tempTotal += parseFloat(item.price * item.count);
    });
    setTotal(tempTotal);
  }, [cart]);

  // let restaurants;
  // let bgColor
  // let color
  // let text
  // let restaurantItems
  // let headerName
  // let images
  // let isVideo
  // let categories
  // let restaurant_id;
  // let isOffCategory
  // let restaurant_is_closed
  // const { data, isLoading, error } = ShowRestaurantSubMenu(room_uuid, params.r_uuid, selected)
  // if (!isLoading) {
  //   if (error) {
  //     // console.log("error", error.message);
  //   } else {

  //     restaurants = data?.data?.data
  //     restaurantItems = restaurants.items;
  //     categories = restaurants?.categories
  //     restaurant_id = restaurants?.restaurant?.id
  //     isOffCategory = restaurants.isOffCategory
  //     restaurant_is_closed = restaurants.restaurant.is_closed

  //     headerName = restaurants.title
  //     isVideo = restaurants.restaurant.real_video ? true : false
  //     images = restaurants.restaurant.real_video ? [restaurants.restaurant.real_video, ...restaurants.restaurant?.images] : restaurants.restaurant?.images
  //     window.scrollTo({
  //       top: 0,
  //       left: 0,
  //     });
  //   }

  // }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  let isbooking = rawPath.includes("RestaurantBooking");
  console.log("isbooking", isbooking);


  const type = localStorage.getItem("type")

  let hideIcon = false




  let restaurant_id;
  let isOffCategory;
  let restaurant_is_closed;
  let headerName;
  let images;
  let isVideo;
  let bgColor;
  let terms_and_conditions;
  let currency;
  let restaurantInfo;
  let restaurant_info;
  let updateisbooking = isbooking ? "&link_type=menu" : "";
  const restaurant_uuid = params.r_uuid;
  const {
    data,
    isLoading,
    isError,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery(
    `restaurant_submenu-${lang}-${restaurant_uuid}-${selected}`,
    ({ pageParam = 1 }) =>
      RestaurantSubMenuAPI(
        {
          room_uuid,
          restaurant_uuid,
          selected,
          pageParam,
          lang,
          isbooking: updateisbooking,
        },
        {
          enabled: false,
          retry: 2,
          cacheTime: 0,
        }
      ),
    {
      getNextPageParam: (data) => {
        const lastPage = data.data?.data?.items?.links?.last_page;
        const currentPage = data.data?.data?.items.links.current_page;
        return currentPage < lastPage ? currentPage + 1 : undefined;
      },
    }
  );

  if (!isLoading) {
    // console.log("ddd", data.pages[0].data.status);
    if (data.pages[0].data.status == false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      const resault = data?.pages[0]?.data.data;
      restaurant_id = resault.restaurant?.id;
      restaurantInfo = resault.restaurant;
      restaurant_info = resault.restaurant;
      isOffCategory = resault.isOffCategory;
      currency = resault.currency;
      restaurant_is_closed = resault.restaurant.is_closed;
      headerName = resault.title;
      bgColor = resault.headerColor;
      terms_and_conditions = resault.restaurant.terms_and_conditions;

      isVideo = resault.restaurant.real_video ? true : false;
      if (resault.restaurant.real_video) {
        if (resault.banners?.length > 0) {
          images = [
            { image: resault.restaurant.real_video },
            ...resault.banners,
          ];
        }
      } else {
        if (resault.banners?.length > 0) {
          images = resault.banners;
        }
      }

      if (type == "in_dining") {
        if ((restaurantInfo?.can_order && !isOffCategory) == false) {
          hideIcon = true
        }
      }
    }
    // isVideo = resault?.restaurant.real_video ? true : false;
    // if (resault.restaurant.real_video) {
    //   if (resault.restaurant?.images == !null) {
    //     images = [resault.restaurant.real_video, ...resault.restaurant?.images];
    //   } else {
    //     images = [resault.restaurant.real_video, resault.restaurant?.image];
    //   }
    // } else {
    //   images =
    //     resault.restaurant?.images !== null
    //       ? resault.restaurant?.images
    //       : [resault.restaurant?.image];
    // }
  }
  console.log("hideIcon", hideIcon);

  useEffect(() => {
    if (ref.current) {
      // console.log("ref.current", ref.current.children);

      const lists = ref.current.children;
      // console.log("ref", ref.current.children);
      // ref.current.children;
      Object.values(lists).map((list, idx) => {
        if (list.classList.contains("active")) {
          const itemWidth = list.offsetWidth;
          const scrollPosition = idx * itemWidth;

          ref.current.scrollTo({
            left: scrollPosition,
            behavior: "smooth",
          });
          //     // Your logic when the "active" class is present
          //     // console.log('The first child has the "active" class');
        }
      });
    }
  }, [data]);
  const h_ref = useRef(null);
  useLayoutEffect(() => {
    if (h_ref.current) {
      if (data?.pages[0].data?.data?.items?.data?.length > 4) {
        console.log(h_ref);
        const divAnimate = h_ref.current.getBoundingClientRect().top - 70;
        console.log("divAnimate", divAnimate);
        const onScroll = () => {
          if (divAnimate < window.scrollY) {
            console.log("ok");
            <div ref={h_ref}></div>;
            h_ref.current.classList.add("fixedSearch");
          } else {
            h_ref.current.classList.remove("fixedSearch");
          }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
      }
    }
  }, [data]);
  let loadMoreRef = useRef();

  useEffect(() => {
    if (!hasNextPage) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && fetchNextPage()),
      {
        root: null,
        margin: "0px",
        treshold: 1.0,
      }
    );
    // restaurant_is_closed = isbooking;
    console.log("restaurant_is_closed", restaurant_is_closed);
    const el = loadMoreRef && loadMoreRef.current;

    if (!el) {
      return;
    }

    observer.observe(el);
  }, [loadMoreRef.current, hasNextPage]);

  const handelNav = (id) => {
    // console.log("iddd", id);
    navigate(`/${room_uuid}/RestaurantSubCat/${params.r_uuid}/${id}`);
  };
  const showData = () => {
    if (isLoading) {
      return <SpaLoader />;
    }
    if (error) {
      return <ErrorPage des={error.message} />;
    }
    return (
      <>
        <HeaderInnerInDining bgColor={bgColor} theme={theme} hideIcon={hideIcon} />
        <main className="bg-white pad-top-0" style={{ marginBottom: "100px" }}>
          {images?.length > 0 &&
            (theme ? (
              <>
                <BannerHome
                  // className={"px-2"}
                  className={"bot_rad"}
                  headerImages={images}
                  isOverllay={true}
                  isVideo={isVideo}
                />
                <div className={`res_info ${images?.length == 0 ? "mt-0" : ""}`}>
                  {" "}
                  <div className="res_name">
                    <h5 className="">
                      {" "}
                      {lang == "EN"
                        ? restaurant_info.display_name
                        : restaurant_info.name}
                    </h5>
                    <p>
                      {restaurant_info?.cuisines?.map((cuisine, idx) => {
                        if (idx + 1 < restaurant_info?.cuisines.length) {
                          return `${cuisine.name}  |  `;
                        } else {
                          return `${cuisine.name}  `;
                        }
                      })}
                    </p>
                  </div>

                </div>
              </>
            ) : (
              <header className="img-header m-0 pt-0">
                <Carosle headerImages={images} isVideo={isVideo} />
              </header>
            ))}

          <div ref={h_ref}>
            <Search
              placeholder="Search for Dishes, Drinks ..."
              room_uuid={room_uuid}
              category="Restaurant&in_dining"
            />
            {terms_and_conditions != "" &&
              (terms_and_conditions != undefined ? (
                <>
                  <div
                    className="mx-4 divhtml"
                    dangerouslySetInnerHTML={{
                      __html: terms_and_conditions,
                    }}
                  />
                </>
              ) : null)}

            <ul
              className="inline-list search"
              ref={ref}
              onMouseDown={onMouseDown}
            >
              {data?.pages[0]?.data?.data?.categories?.map((category, idx) => {
                // console.log("category", category.count_sub_categories);
                return category.count_sub_categories == 0 ? (
                  <li
                    className={selected == category?.id && "active"}
                    onMouseDown={() => {
                      setSelected(category?.id);
                    }}
                    key={idx}
                  >
                    <Button>{category?.name}</Button>
                  </li>
                ) : (
                  <li
                    className={selected == category?.id && "active"}
                    key={idx}
                    onClick={(id) => handelNav(category?.id)}
                  >
                    <Button>{category?.name}</Button>
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            {data?.pages?.map((page) =>
              page?.data?.data?.items?.data.map((item) => {
                return (
                  <div className={`add-post-container add-cart`}>
                    <AddCartPost
                      item={item}
                      restaurantId={restaurant_id}
                      isOffCategory={isOffCategory}
                      restaurant_is_closed={restaurant_is_closed}
                      isbooking={isbooking}
                      currency={currency}
                      restaurantInfo={restaurantInfo}
                    />
                  </div>
                );
              })
            )}
            <div
              className="ref_loading"
              ref={loadMoreRef}
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            ></div>
            <div>
              {isFetchingNextPage ? (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              ) : hasNextPage ? (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <ViewCart room_uuid={room_uuid} dining={true} hideIcon={hideIcon} />
        </main>
      </>

    );
  };
  return (
    <div className={theme ? "food" : ""}>

      {showData()}
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        centered={false}
        className="view-cart-modal"
      >
        <Modal.Body className={`${lang == "ar" ? "ar_lang" : ""}`}>
          <Button className="close-btn" onClick={handleClose}>
            <i className="fa fa-times-circle-o"></i>
          </Button>
          <ViewCartModal />
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default InDiningRestaurantSubMenu;
