import React, { useEffect } from "react";
import RestaurantsLoader from "../../Components/Loaders/RestaurantsLoader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ShowScanResturants } from "../../API/In-Room-DiningAPI";
import HeaderInner from "../../Components/HeaderInner";
import { Bounce } from "react-reveal";
import CarosleRestaurant from "../../Components/CarosleRestaurant";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";

const RestaurantsScan = () => {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  let restaurants;
  let bgColor;
  let color;
  let text;
  let headerImg = [];
  const { data, isLoading, error } = ShowScanResturants(room_uuid, lang);

  if (!isLoading) {
    if (data.data.status == false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else if (!error) {
      restaurants = data.data.data.restaurants;
      color = data.data.data.color;
      bgColor = data.data.data.headerColor;
      text = data.data.data.center_header;
      document.documentElement.style.setProperty("--color", color);
      document.documentElement.style.setProperty("--color_t", `${color}b3`);

      document.documentElement.style.setProperty("--header_color", bgColor);
      //   color = data.data.data.color;
      //   bgColor = data.data.data.header_color;
      //   text = data.data.data.center_header;
      // console.log("restaurants", restaurants);
      // headerImg = restaurants
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <div className={theme ? "food" : ""}>
      <HeaderInner
        headerName={"Restaurant Booking"}
        scan={true}
        theme={theme}
      />
      <main className="bg-white " style={{ paddingTop: "100px!important", marginBottom: "0px!important" }}>
        {isLoading ? (
          <RestaurantsLoader />
        ) : (
          theme ?
            <div className="box_food">
              {restaurants?.map((restaurant, idx) => {
                return (
                  <Link
                    key={idx}
                    className={` ${restaurant.is_closed
                      ? "closed restaurant-option"
                      : ""
                      }`}
                    to={`/${restaurant.uuid}/restaurant-menu/categories`}
                  >
                    <div style={{ position: "relative" }}>
                      <img src={restaurant?.image} alt="" className="booking_img" />
                      <div className="closed-tag"></div>
                      {restaurant?.is_closed && (
                        <span className="open_at">
                          {restaurant.opens_at
                            ? `Open at ${restaurant.opens_at}`
                            : `Closed`}
                        </span>
                      )}

                      <img src={Overllay} alt="" className="overlay" />
                      <div className="text_food">
                        <h5 className="">
                          {" "}
                          {lang == "EN" ? restaurant.display_name : restaurant.name}
                        </h5>
                        <p>
                          {restaurant?.cuisines?.map((cuisine, idx) => {
                            if (idx + 1 < restaurant?.cuisines.length) {
                              return `${cuisine.name}  |  `;
                            } else {
                              return `${cuisine.name}  `;
                            }
                          })}
                        </p>
                      </div>{" "}
                    </div>

                  </Link>
                );
              })}


            </div> :
            <>
              {restaurants?.length > 1
                ? restaurants.map((restaurant, idx) => {
                  return (
                    <div
                      className={`booking_res general-post-container restaurant-post ${restaurant.is_closed
                        ? "closed restaurant-option"
                        : "restaurant-option"
                        }`}
                      key={idx}
                    >
                      <Bounce left>
                        <Link
                          to={`/${restaurant.uuid}/restaurant-menu/categories`}
                          className="general-post"
                        >
                          <div className="closed-tag"></div>
                          {restaurant?.is_closed && (
                            <span className="open_at">
                              {restaurant.opens_at
                                ? `Open at ${restaurant.opens_at}`
                                : `Closed`}
                            </span>
                          )}
                          <div className="img-pnl ">
                            {/* <img
                                src={restaurant.image}
                                alt="Post"
                                className="visible"
                              /> */}
                            {
                              // headerImg.push(restaurant.image)
                            }
                            <CarosleRestaurant
                              headerImages={[restaurant.image]}
                            />
                          </div>
                          <div className="txt-pnl">
                            <h2>
                              {" "}
                              {lang == "EN"
                                ? restaurant.display_name
                                : restaurant.name}
                            </h2>
                            <p>{restaurant.cuisine_name}</p>
                          </div>
                        </Link>
                      </Bounce>
                    </div>
                  );
                })
                : null}
            </>
        )}

        {/*    <div className="general-post-container restaurant-post">
          <Link to="/RestaurantMenu" className="general-post">
            <div className="closed-tag"></div>
            <div
              className={
                timer ? "img-pnl smooth" : "img-pnl animated-background smooth"
              }
            >
              <img src={Post1} alt="Post" className={timer ? "visible" : ""} />
            </div>
            <div className="txt-pnl">
              <h2 className={!timer && "animated-background"}>
                {timer && "In-Room Dining"}
              </h2>
              <p className={!timer && "animated-background"}>
                {timer && "International"}
              </p>
            </div>
          </Link>
        </div>
        <div className="general-post-container restaurant-post">
          <Link to="/RestaurantMenu" className="general-post">
            <div className="closed-tag"></div>
            <div
              className={
                timer ? "img-pnl smooth" : "img-pnl animated-background smooth"
              }
            >
              <img src={Post2} alt="Post" className={timer ? "visible" : ""} />
            </div>
            <div className="txt-pnl">
              <h2 className={!timer && "animated-background"}>
                {timer && "Cafe Society"}
              </h2>
              <p className={!timer && "animated-background"}>
                {timer && "Pizza, Eastern, Western"}
              </p>
            </div>
          </Link>
        </div>
        <div className="general-post-container restaurant-post">
          <Link to="/RestaurantMenu" className="general-post">
            <div className="closed-tag"></div>
            <div
              className={
                timer ? "img-pnl smooth" : "img-pnl animated-background smooth"
              }
            >
              <img src={Post3} alt="Post" className={timer ? "visible" : ""} />
            </div>
            <div className="txt-pnl">
              <h2 className={!timer && "animated-background"}>
                {timer && "Hawa Bahri Restaurant"}
              </h2>
              <p className={!timer && "animated-background"}>
                {timer && "Eastern Cuisine"}
              </p>
            </div>
          </Link>
        </div> */}
      </main>
    </div>
  );
};

export default RestaurantsScan;
