import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../src/Components/NavBar";
import Searchbar from "./Components/Searchbar";
import GeneralPost from "./Components/GeneralPost";
import iconfire from "../src/assets/images/icons/icon-fire.png";
import icontime from "../src/assets/images/icons/icon-time.png";
import ReactGA from "react-ga4";
import TitleHeader from "./new-components/title/Title";
import useDraggableScroll from "use-draggable-scroll";
import { Button } from "react-bootstrap";
import { ShowSearchAPI } from "./API/SearchAPI";
import axios from "./axios/index";
import RItems from "./Components/Search/RItems";
import RCategories from "./Components/Search/RCategories";
import RResturants from "./Components/Search/RResturants";
import Title from "./Components/Search/Title";
import PharmacyCartPost from "./Components/PharmacyCartPost";
import PharmacySearch from "./Components/Search/PharmacySearch";
import CarSearch from "./Components/Search/CarSearch";
import EntertainmentSearch from "./Components/Search/EntertainmentSearch";
import PhCategories from "./Components/Search/PhCategories";
import { useTranslation } from "react-i18next";
import SpaLoader from "./Components/Loaders/SpaLoader";
import { useDebouncedCallback } from "use-debounce";
import SPASearch from "./Components/Search/SPASearch";
import SupermarketCartPost from "./Components/SupermarketCartPost";
import HeaderInner from "./Components/HeaderInner";
import NewFooter from "./NewFooter";
import HeaderInnerInDining from "./Components/HeaderInnerInDining";

function SearchPage() {
  const inputRef = useRef(null);

  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("lang");
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  let category = location.search.replace("?", "").replace("%20", " ").replace("&in_dining", "");
  const [loadign, setLoading] = useState(false);

  console.log("location", location.search);

  const inDining = location.search.includes("in_dining")


  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const ref = useRef(null);
  const navigate = useNavigate();
  const { onMouseDown } = useDraggableScroll(ref);

  useEffect(() => {
    setLoading(true);
    const GetCategories = async () => {
      // axios.get(`/648ed77e-da78-4987-9b86-de515659fc2c`).then((res) => {
      axios
        .get(`/${room_uuid}/search`, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          console.log("search", res.data.data.categories);
          const data = res.data.data;
          setCategories(data.categories.split(","));
          setLoading(false);
          const lists = ref.current.children;
          console.log("refcmjfffls", ref.current.children);
          // ref.current.children;
          Object.values(lists).map((list, idx) => {
            if (list.classList.contains("active")) {
              const itemWidth = list.offsetWidth;
              const scrollPosition = idx * itemWidth;

              ref.current.scrollTo({
                left: scrollPosition,
                behavior: "smooth",
              });
              //     // Your logic when the "active" class is present
              //     // console.log('The first child has the "active" class');
            }
          });
        });
    };
    GetCategories();

    if (ref.current) {
      // console.log("ref.current", ref.current.children);
      // const lists = ref.current.children;
      // console.log("refcmjfffls", ref.current.children);
      // // ref.current.children;
      // Object.values(lists).map((list, idx) => {
      //   if (list.classList.contains("active")) {
      //     const itemWidth = list.offsetWidth;
      //     const scrollPosition = idx * itemWidth;
      //     ref.current.scrollTo({
      //       left: scrollPosition,
      //       behavior: "smooth",
      //     });
      //     //     // Your logic when the "active" class is present
      //     //     // console.log('The first child has the "active" class');
      //   }
      // });
    }
  }, []);

  useEffect(() => {
    console.log(`you have pressed the button ${count} times`);
  }, [count]);

  <button onClick={() => setCount(count + 1)}>Click Me</button>;
  const [selected, setSelected] = useState(category || "Restaurant");
  const [search, setSearch] = useState("");
  const [resItems, setResItems] = useState([]);
  const [resCategories, setResCategories] = useState([]);
  const [resResturans, setResResturans] = useState([]);
  const [phItems, setphItems] = useState([]);
  const [phCategories, setphCategories] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);
  const [supItems, setsupItems] = useState([]);
  const [supCategories, setsupCategories] = useState([]);
  const [suparmacies, setsuparmacies] = useState([]);
  const [fItems, setfItems] = useState([]);
  const [fCategories, setfCategories] = useState([]);
  const [florists, setFlorists] = useState([]);
  const [cars, setCars] = useState([]);
  const [entertainment, setEntertainment] = useState([]);
  const [health, setHealth] = useState([]);
  const [maintenance, setMaintenance] = useState([]);
  const [spa, setSpa] = useState([]);
  const [linkPage, setLinkPage] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  const getData = () => {
    setLoadingData(true);
    axios
      .get(
        `/${room_uuid}/search?search_category=${encodeURIComponent(
          selected
        )}&search=${search}&page=${page}`,
        {
          headers: {
            lang: lang,
          },
        }
      )
      .then((res) => {
        ReactGA.event({
          category: "Search",
          action: "Fetch_Data",
          value: `Category name: ${category}, Search words: ${search}`,
        });
        const data = res?.data?.data.data;
        setLoading(false);
        console.log("rs", data);
        setLinkPage(data.pagination_links);

        setResCategories(data.rCategories);
        setResResturans(data.restaurants);

        setphCategories(data.pCategories);
        setPharmacies(data.pharmacies);

        if (page > 1) {
          setfItems([...fItems, ...data.fItems]);
          setCars([...cars, ...data.cars]);
          setsupItems([...supItems, ...data.sItems]);
          setResItems([...resItems, ...data.rItems]);
          setphItems([...phItems, ...data.pItems]);
        } else {
          setsupItems(data.sItems);
          setResItems(data.rItems);
          setphItems(data.pItems);

          setfItems(data.fItems);
          setCars(data.cars);
        }

        setsupCategories(data.sCategories);
        setsuparmacies(data.supermarkets);

        setfCategories(data.fCategories);
        setFlorists(data.florists);
        setEntertainment(data.entertainmentCategories);
        setHealth(data.healthcareCategories);
        setSpa(data.spaServices);

        setLoadingData(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoadingData(false);
      });
  };

  useEffect(() => {
    if (search != "") {
      // Call your search function here
      getData();
    }
  }, [search, selected, page]);

  const handleInputChange = useDebouncedCallback((event) => {
    setSearch(event.target.value);
  }, 800);

  // }, [search, selected]);
  console.log("super", search);
  // const categories = [
  //   "Restaurant",
  //   "Flowers",
  //   "Pharmacy",
  //   "Supermarket",
  //   "Car Rental",
  //   "Entertainment",
  //   "Healthcare & Wellness",
  //   // "Water",
  //   // "Spa",
  //   // "Salon At Home",
  //   // "Maintenance",
  // ];
  console.log("selected", linkPage);

  useEffect(() => {
    if (ref.current) {
      // console.log("ref.current", ref.current.children);

      const lists = ref.current.children;
      // console.log("ref", ref.current.children);
      // ref.current.children;
      Object.values(lists).map((list, idx) => {
        if (list.classList.contains("active")) {
          const itemWidth = list.offsetWidth;
          const scrollPosition = idx * itemWidth;

          ref.current.scrollTo({
            left: scrollPosition,
            behavior: "smooth",
          });
          //     // Your logic when the "active" class is present
          //     // console.log('The first child has the "active" class');
        }
      });
    }
    inputRef.current.focus();
  }, [selected]);

  console.log("page < linkPage?.length - 2 ", page);

  const logo = localStorage.getItem("logo");

  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  // <HeaderInner logo={logo} />


  return (
    <div className={theme ? "food" : ""}>
      {theme ? <TitleHeader title={"Search"} hideIcon={inDining} /> :
        inDining ?
          <HeaderInnerInDining hideIcon={inDining} /> :
          <HeaderInner logo={logo} />}

      <main className="home-main pad-top-0 search_page">
        <div className="Search-bar less searchPage mt-3">
          <input
            className="form-control"
            ref={inputRef}
            type="search"
            placeholder={t("Search")}
            style={{ backgroundColor: "#fff" }}
            onChange={handleInputChange}
          />
          <i class="fa fa-search" aria-hidden="true"></i>
        </div>
        {loadign ? (
          <SpaLoader />
        ) : (
          <>
            {
              inDining ? null :
                <ul
                  className="inline-list search"
                  style={{ padding: "0 10px" }}
                  ref={ref}
                  onMouseDown={onMouseDown}
                >
                  {categories?.map((category) => {
                    return (
                      <li
                        className={selected === category && "active"}
                        onClick={() => {
                          setSelected(category);
                          ReactGA.event({
                            category: "Click on the category in search page",
                            action: "Click",
                            value: `Category name: ${category}`,
                          });
                          setPage(1);
                          setResItems([]);
                          setfItems([]);
                          setphItems([]);
                          setsupItems([]);
                          setCars([]);
                        }}
                      >
                        <Button>{t(`${category}`)} </Button>
                      </li>
                    );
                  })}
                </ul>
            }


            {resCategories.length > 0 && <Title des="Categories" />}
            <ul className="display-list three-items v2 v3">
              {resCategories.length > 0 &&
                resCategories?.map((item) => {
                  return (
                    <RCategories
                      category={item}
                      room_uuid={room_uuid}
                      url="RestaurantSubMenu"
                      theme={theme}
                    />
                  );
                })}
            </ul>
            {resItems.length > 0 && <Title des="Items" />}
            {resItems.length > 0 &&
              resItems?.map((item) => {
                return (
                  <div
                  // onClick={() =>
                  //   navigate(
                  //     `/${room_uuid}/reastrant/${item.restaurant_id}/${item.id}`
                  //   )
                  // }
                  >
                    <RItems
                      item={item}
                      lang={lang}
                      room_uuid={room_uuid}
                      theme={theme}
                    />
                    ;
                  </div>
                );
              })}

            {resItems.length > 0 && loadingData && (
              <div class="d-flex justify-content-center mb-2">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div>
            )}
            {resItems.length > 0 && page < linkPage?.length - 2 && (
              <div className="load_more_div mb-4">
                <Button
                  disabled={loadingData}
                  className="load_more"
                  onClick={(e) => setPage(page + 1)}
                >
                  {loadingData ? "Loading..." : "Load More"}
                </Button>
              </div>
            )}
            {resResturans.length > 0 && <Title des="Restaurants" />}
            <div>
              {resResturans.length > 0 &&
                resResturans?.map((item) => {
                  return (
                    <RResturants
                      restaurant={item}
                      room_uuid={room_uuid}
                      url="RestaurantMenu"
                      theme={theme}
                    />
                  );
                })}
            </div>

            {phCategories.length > 0 && <Title des="Categories" />}
            <ul className="display-list three-items v2 v3">
              {phCategories.length > 0 &&
                phCategories?.map((item) => {
                  return (
                    <PhCategories
                      category={item}
                      room_uuid={room_uuid}
                      url="PharmacySubMenu"
                      apiID="pharmacy_id"
                      apiUUID="pharmacie_uuid"
                    />
                  );
                })}
            </ul>
            {phItems.length > 0 && <Title des="Items" />}
            {phItems.length > 0 &&
              phItems?.map((item) => {
                return (
                  <div
                    className="add-post-container add-cart"
                  // onClick={() =>
                  //   navigate(
                  //     `/${room_uuid}/pharmacy/${item.pharmacy_id}/${item.id}`
                  //   )
                  // }
                  >
                    <PharmacyCartPost
                      item={item}
                      pharmacyId={item.pharmacy_id}
                      search={false}
                      subTitle={item.pharmacy_name}
                    />
                  </div>
                );
              })}
            {phItems.length > 0 && loadingData && (
              <div class="d-flex justify-content-center mb-2">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div>
            )}
            {phItems.length > 0 && page < linkPage?.length - 2 && (
              <div className="load_more_div mb-4">
                <Button
                  disabled={loadingData}
                  className="load_more"
                  onClick={(e) => setPage(page + 1)}
                >
                  {loadingData ? "Loading..." : "Load More"}
                </Button>
              </div>
            )}

            {pharmacies.length > 0 && <Title des="Pharmacies" />}
            <div>
              {pharmacies.length > 0 &&
                pharmacies?.map((item) => {
                  return (
                    <PharmacySearch
                      pharmacie={item}
                      room_uuid={room_uuid}
                      url="pharmacy"
                    />
                  );
                })}
            </div>

            {supCategories.length > 0 && <Title des="Categories" />}
            <ul className="display-list three-items v2 v3">
              {supCategories.length > 0 &&
                supCategories?.map((item) => {
                  return (
                    <PhCategories
                      category={item}
                      room_uuid={room_uuid}
                      url="SupermarketSubMenu"
                      apiID="supermarket_id"
                      apiUUID="supermarket_uuid"
                      type="supermarket"
                    />
                  );
                })}
            </ul>
            {supItems.length > 0 && <Title des="Items" />}
            {supItems.length > 0 &&
              supItems?.map((item) => {
                return (
                  <div
                    className="add-post-container add-cart supermarket_box"
                  // onClick={() =>
                  //   navigate(
                  //     `/${room_uuid}/supermarket/${item.supermarket_id}/${item.id}`
                  //   )
                  // }
                  >
                    <SupermarketCartPost
                      item={item}
                      pharmacyId={item.supermarket_id}
                      // search={true}
                      subTitle={item.supermarket_name}
                      type="supermarket"
                    />
                  </div>
                );
              })}
            {supItems.length > 0 && loadingData && (
              <div class="d-flex justify-content-center mb-2">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div>
            )}
            {supItems.length > 0 && page < linkPage?.length - 2 && (
              <div className="load_more_div mb-4">
                <Button
                  disabled={loadingData}
                  className="load_more"
                  onClick={(e) => setPage(page + 1)}
                >
                  {loadingData ? "Loading..." : "Load More"}
                </Button>
              </div>
            )}
            {/* {suparmacies.length > 0 && <Title des="Supermarkets" />}
            <div>
              {suparmacies.length > 0 &&
                suparmacies?.map((item) => {
                  return (
                    <PharmacySearch
                      pharmacie={item}
                      room_uuid={room_uuid}
                      url="supermarket"
                    />
                  );
                })}
            </div> */}

            {fCategories.length > 0 && <Title des="Categories" />}
            <ul className="display-list three-items v2 v3">
              {fCategories.length > 0 &&
                fCategories?.map((item) => {
                  return (
                    <PhCategories
                      category={item}
                      room_uuid={room_uuid}
                      url="FlowersSubMenu"
                      apiID="florist_id"
                      apiUUID="florist_uuid"
                    />
                  );
                })}
            </ul>
            {fItems.length > 0 && <Title des="Items" />}
            {fItems.length > 0 &&
              fItems?.map((item) => {
                return (
                  <div
                    className="add-post-container add-cart"
                  // onClick={() =>
                  //   navigate(
                  //     `/${room_uuid}/florists/${item.florist_id}/${item.id}`
                  //   )
                  // }
                  >
                    <PharmacyCartPost
                      item={item}
                      flowerId={item.florist_id}
                      search={false}
                      subTitle={item.florist_name}
                    />
                  </div>
                );
              })}
            {fItems.length > 0 && loadingData && (
              <div class="d-flex justify-content-center mb-2">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div>
            )}
            {fItems.length > 0 && page < linkPage?.length - 2 && (
              <div className="load_more_div mb-4">
                <Button
                  disabled={loadingData}
                  className="load_more"
                  onClick={(e) => setPage(page + 1)}
                >
                  {loadingData ? "Loading..." : "Load More"}
                </Button>
              </div>
            )}
            {florists.length > 0 && <Title des="Florists" />}
            <div>
              {florists.length > 0 &&
                florists?.map((item) => {
                  return (
                    <PharmacySearch
                      pharmacie={item}
                      room_uuid={room_uuid}
                      url="flower"
                    />
                  );
                })}
            </div>
            {cars.length > 0 && <Title des="Cars" />}
            <ul className="display-list car-list two-items v2">
              {cars.length > 0 &&
                cars?.map((item) => {
                  return <CarSearch car={item} room_uuid={room_uuid} />;
                })}
            </ul>
            {cars.length > 0 && loadingData && (
              <div class="d-flex justify-content-center mb-2">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div>
            )}
            {cars.length > 0 && page < linkPage?.length - 2 && (
              <div className="load_more_div mb-5">
                <Button
                  disabled={loadingData}
                  className="load_more"
                  onClick={(e) => setPage(page + 1)}
                >
                  {loadingData ? "Loading..." : "Load More"}
                </Button>
                <br />
              </div>
            )}
            {entertainment.length > 0 && <Title des="Categories" />}
            <div>
              {entertainment.length > 0 &&
                entertainment?.map((item) => {
                  return (
                    <EntertainmentSearch
                      x={item}
                      url={"EntertainmentDetail"}
                      room_uuid={room_uuid}
                    />
                  );
                })}
            </div>
            {health.length > 0 && <Title des="Categories" />}
            <div>
              {health.length > 0 &&
                health?.map((item) => {
                  return (
                    <EntertainmentSearch
                      x={item}
                      url={"HealthcareOrder"}
                      room_uuid={room_uuid}
                    />
                  );
                })}
            </div>
            {maintenance.length > 0 && <Title des="Categories" />}
            <div>
              {maintenance.length > 0 &&
                maintenance?.map((item) => {
                  return <EntertainmentSearch x={item} room_uuid={room_uuid} />;
                })}
            </div>
          </>
        )}
        {spa.length > 0 && <Title des="Items" />}
        {spa.length > 0 &&
          spa?.map((item) => {
            return <SPASearch item={item} />;
          })}
      </main>
      {theme && !inDining ? <NewFooter /> : null}
    </div>
  );
}
export default SearchPage;
