import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { ShowWakeUpCall } from "../../API/Concierge";
import ReactGA from "react-ga4";

import axios from "../../axios";
import { Toast } from "primereact/toast";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { useTranslation } from "react-i18next";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import BannerServices from "../../new-components/banner-home/BannerServices";
import { phoneFormat } from "../../constants/api";
function WakeUpCall() {
  const navigate = useNavigate();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const [phone, setPhone] = useState();
  const [name, setName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const { t } = useTranslation();
  const toast = useRef(null);
  const lang = localStorage.getItem("lang");
  // const theme = location.search;
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";

  let other;
  let text;
  const { data, isLoading } = ShowWakeUpCall(room_uuid, lang);
  if (!isLoading) {
    let newData = data.data.data;
    other = newData;
    text = newData?.center_header;

    // console.log("ShowOther", newData);
  }

  const sendData = {
    type_id: "Wake-up-call",
    name,
    phone,
  };
  const handelBook = () => {
    setIsDisabled(true);
    ReactGA.event({
      category: "Book service concierge ",
      action: "Click",
      value: `concierge type: other`,
    });
    axios
      .post(`/${room_uuid}/concierge/book-service`, sendData, {
        headers: {
          lang: lang,
        },
      })
      .then((res) => {
        if (res.data.status == false) {
          toast.current.show({
            severity: "error",
            summary: t("Error"),
            detail: t(`${res.data.message}`),
            life: 4000,
          });
        }
        // console.log("res", res);
        if (res.data.status) {
          navigate(`/${room_uuid}/HKOrderPlaced`, {
            state: {
              metadata: {
                res: res.data.data,
              },
            },
          });
        }

        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(false);
        console.log(err);
      });

  };

  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={text} /> : <HeaderInner headerName={text} />}
      <main className="home-main bg-white back-white book-table pad-top-0">
        <Toast ref={toast} position="bottom-center" />

        {isLoading ? (
          <SpaLoader />
        ) : (
          <>
            {theme ? (
              <BannerServices headerImages={[other.image]} />
            ) : (
              <div className="con-order-img">
                <img src={other.image} alt="" />
              </div>
            )}

            <p className="bk-title mt-3">{t(`${other.title}`)}</p>
            <div className="px-5">
              <div className="con-email">
                <Form.Group
                  className="mb-3  align-items-center dark-placeholder justify-content-start "
                  controlId="formBasicname"
                >
                  <Form.Control
                    type="email"
                    placeholder={t("Your Name (Optional)")}
                    className="w-100 py-3"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

              </div>
              <div className="con-phone">
                <Form.Group className="mb-3 w-100 ">
                  <PhoneInput
                    country={phoneFormat}
                    containerclassName="ml-4"
                    placeholder="50 123 4567"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                </Form.Group>
              </div>

            </div>
            <div className="padd-div text-center mt-5">
              <Button
                className="reg-btn book lg"
                disabled={isDisabled}
                onClick={handelBook}
              // to={`/${room_uuid}/HKOrderPlaced`}
              >
                {t("Send")}
              </Button>
            </div>
            <div className="padd-div text-center">
              {/* <h3 className="text-center">
                <TaxInclusive />
              </h3>{" "} */}
            </div>
            <div className="spacer-40 my-5"></div>
          </>
        )}
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default WakeUpCall;
