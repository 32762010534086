import React, { useEffect, useState } from "react";
import "./New.scss";
import HomeIcon from "./assets/new_imgs/Group 38660.png";
import SearshIcon from "./assets/new_imgs/Path 445.png";
import CartIcon from "./assets/new_imgs/Path 444.png";
import WhatsappIcon from "./assets/new_imgs/Group 38659.png";
import MenuIcon from "./assets/new_imgs/Group 38658.png";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "./axios";
import { ShowCheckout } from "./API/CheckoutAPI";

const NewFooter = () => {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  let flattenedArray;
  let cart_count = 0;
  let check;
  if (room_uuid) {
    const { data, isLoading, error } = ShowCheckout(room_uuid);

    if (!isLoading) {
      check = data?.data?.data;
      console.log("check", check);

      const tempItems = check?.order?.map((order) => {
        return order?.items.map((aa) => aa);
      });
      const combo_meal = check?.order[0]?.combo_meals?.length || 0;
      console.log("combo_meal", combo_meal);
      if (tempItems) {
        flattenedArray = [].concat(...tempItems);
        // console.log("flattenedArray", flattenedArray);
        cart_count =
          flattenedArray?.length == 0
            ? 0
            : flattenedArray
              ?.map((item) => item?.quantity)
              .reduce((acc, curr) => (acc += curr));
        cart_count = cart_count + combo_meal;
      }
    }
  }
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [isWhatsapp, setIsWhatsapp] = useState(false);

  useEffect(() => {
    const getData = () => {
      axios.get(`/${room_uuid}/qr-code?source=footer`).then((res) => {
        const data = res.data.data;
        localStorage.setItem("room_number", data?.room_number);
        localStorage.setItem("currency", data?.currency || "AED");
        localStorage.setItem("new_design", data?.use_new_design);
        setIsWhatsapp(data?.whatsapp_enabled);
        setWhatsappNumber(data?.whatsapp_number);
        localStorage.setItem("tax_inclusive", data?.tax_inclusive);
        localStorage.setItem("show_prices", data?.show_prices);
        localStorage.setItem("has_extra_services", data?.has_extra_services);
        localStorage.setItem("room_number", data?.room_number);
        localStorage.setItem("businessType", data.businessType);

        // setIsWhatsapp(data?.whatsapp_enabled);
        // setWhatsappNumber(data?.whatsapp_number);
        // color = data?.color
        // setColor(data?.color);
        if (data?.color) {
          document.documentElement.style.setProperty("--color", data?.color);
          document.documentElement.style.setProperty(
            "--color_t",
            `${data?.color}b3`
          );

          document.documentElement.style.setProperty(
            "--header_color",
            data?.header_color
          );
        }
        const numbersArray = data?.room_number.match(/\d+/g).join("");
        let result;

        if (numbersArray) {
          if (numbersArray.length > 2) {
            result = parseInt(numbersArray.slice(0, 3), 10);
          } else {
            result = parseInt(numbersArray, 10);
          }
        }
      });
    };
    getData();
  }, []);
  const params = useParams();
  return (
    <div className="new_footer">
      <ul>
        <li>
          <Link to={`/${params.room_uuid}/menu`}>
            <img src={MenuIcon} alt="" />
          </Link>
        </li>
        <li>
          <Link to={`/${params.room_uuid}/search`}>
            <img src={SearshIcon} alt="" />
          </Link>
        </li>
        <li>
          <Link to={`/${params.room_uuid}/qr-code`}>
            <img src={HomeIcon} alt="" />
          </Link>
        </li>
        <li>
          <Link to={`/${params.room_uuid}/cart`}>
            <img src={CartIcon} alt="" />
            <span>{cart_count}</span>
          </Link>
        </li>
        {isWhatsapp && (
          <li>
            <a href={`https://wa.me/${whatsappNumber}`}>
              <img src={WhatsappIcon} alt="" />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default NewFooter;
