import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import AddCartPost from "../../Components/AddCartPost";
import { Button, Modal } from "react-bootstrap";
import ViewCartModal from "../../Components/ViewCartModal";
import ReactGA from "react-ga4";
import "./FoodAndBeverages.scss";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useDraggableScroll from "use-draggable-scroll";
import ErrorPage from "../../Components/ErrorPage";
import { RestaurantSubMenuAPI } from "../../API/In-Room-DiningAPI";
import ViewCart from "../../Components/ViewCart";
import { useInfiniteQuery } from "react-query";
import Search from "../../Components/Search";
import NewLoader from "../../new-components/title/Loader/NewLoader";
import BannerHome from "../../new-components/banner-home/BannerHome";
import Title from "../../new-components/title/Title";
function InDiningItems() {
  const params = useParams();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(params.cat);
  const lang = localStorage.getItem("lang");

  const navigate = useNavigate();
  const ref = useRef(null);
  const urRef = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = params.room_uuid;
  const handleClose = () => setShow(false);

  let isbooking = rawPath.includes("restaurant-booking");
  let restaurant_id;
  let restaurant_info;
  let isOffCategory;
  let restaurant_is_closed;
  let headerName;
  let terms_and_conditions;
  let images;
  let isVideo;
  let currency;
  let canorder;
  isbooking = isbooking ? "&link_type=menu" : "";
  const restaurant_uuid = params.restaurant_uuid;
  const {
    data,
    isLoading,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    `restaurant_submenu-${lang}-${restaurant_uuid}-${selected}`,
    ({ pageParam = 1 }) =>
      RestaurantSubMenuAPI(
        {
          room_uuid,
          restaurant_uuid,
          selected,
          pageParam,
          lang,
          isbooking,
        },
        {
          enabled: false,
          retry: 2,
          cacheTime: 0,
        }
      ),
    {
      getNextPageParam: (data) => {
        const lastPage = data.data?.data?.items?.links?.last_page;
        const currentPage = data.data?.data?.items.links.current_page;
        return currentPage < lastPage ? currentPage + 1 : undefined;
      },
    }
  );

  if (!isLoading) {
    if (data.pages[0].data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      const resault = data?.pages[0]?.data.data;
      restaurant_id = resault.restaurant?.id;
      restaurant_info = resault.restaurant;
      isOffCategory = resault.isOffCategory;
      canorder = resault.canOrder;
      currency = resault.currency;
      restaurant_is_closed = resault.restaurant.is_closed;
      terms_and_conditions = resault.restaurant.terms_and_conditions;
      headerName = resault.title;
      isVideo = resault.restaurant.real_video ? true : false;
      if (resault.restaurant.real_video) {
        if (resault.banners?.length > 0) {
          images = [
            { image: resault.restaurant.real_video },
            ...resault.banners,
          ];
        }
      } else {
        if (resault.banners?.length > 0) {
          images = resault?.banners;
        }
      }
    }
  }
  let h_ref = useRef(null);
  let pharmacyItemRef = useRef(null);
  useEffect(() => {
    if (ref.current) {
      const lists = ref.current.children;
      Object.values(lists).map((list, idx) => {
        if (list.classList.contains("active")) {
          const itemWidth = list.offsetWidth;
          const scrollPosition = idx * itemWidth;

          ref.current.scrollTo({
            left: scrollPosition,
            behavior: "smooth",
          });
        }
      });
    }
  }, [data]);

  useLayoutEffect(() => {
    if (h_ref.current) {
      if (data?.pages[0].data?.data?.items?.data?.length > 4) {
        const divAnimate = h_ref.current.getBoundingClientRect().top - 0;
        const heightDiv = h_ref.current.getBoundingClientRect().height;

        const onScroll = () => {
          if (divAnimate < window.scrollY) {
            h_ref.current.classList.add("fixedSearch");
            pharmacyItemRef.current.style.paddingTop = `${Math.floor(
              heightDiv
            )}px`;
          } else {
            h_ref.current.classList.remove("fixedSearch");
            pharmacyItemRef.current.style.paddingTop = `0px`;
          }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
      }
    }
  }, [data]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  let loadMoreRef = useRef();

  useEffect(() => {
    if (!hasNextPage) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && fetchNextPage()),
      {
        root: null,
        margin: "0px",
        treshold: 1.0,
      }
    );
    const el = loadMoreRef && loadMoreRef.current;

    if (!el) {
      return;
    }

    observer.observe(el);
  }, [loadMoreRef.current, hasNextPage]);

  const handelNav = (id) => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    navigate(`/${room_uuid}/food-and-beverages/in-room-dining/${restaurant_uuid}/${id}`);
  };
  const showData = () => {
    if (isLoading) {
      return <NewLoader />;
    }
    if (error) {
      return <ErrorPage des={error.message} />;
    }
    return (
      <>
        <Title title={headerName} />
        {images?.length > 0 && (
          <BannerHome
            className={"bot_rad"}
            headerImages={images}
            isVideo={isVideo}
            isOverllay={true}
          />
        )}
        <div ref={h_ref}>
          <div className={`res_info ${images?.length === 0 ? "mt-0" : ""}`}>
            {" "}
            <div className="res_name">
              <h5 className="">
                {lang === "EN"
                  ? restaurant_info.display_name
                  : restaurant_info.name}
              </h5>
              <p>
                {restaurant_info?.cuisines?.map((cuisine, idx) => {
                  if (idx + 1 < restaurant_info?.cuisines.length) {
                    return `${cuisine.name}  |  `;
                  } else {
                    return `${cuisine.name}  `;
                  }
                })}
              </p>
            </div>
            {restaurant_info?.show_booking === 1 && (
              <button
                onClick={() =>
                  navigate(
                    `/${params.room_uuid}/BookTable/${restaurant_info.uuid}/${restaurant_info.id}?theme=true`
                  )
                }
                className="reg-btn"
              >
                Book a Table
              </button>
            )}
          </div>
          <Search
            placeholder="Search for Dishes, Drinks ..."
            room_uuid={room_uuid}
            category="Restaurant"
            redirect="search"
          />
          {terms_and_conditions !== "" &&
            (terms_and_conditions !== undefined ? (
              <>
                <div
                  className="mx-4 divhtml"
                  dangerouslySetInnerHTML={{
                    __html: terms_and_conditions,
                  }}
                />
              </>
            ) : null)}

          <ul
            className="inline-list search"
            ref={ref}
            onMouseDown={onMouseDown}
            id=""
          >
            {data?.pages[0]?.data?.data?.categories?.map((category, idx) => {
              return category.count_sub_categories === 0 ? (
                <li
                  className={selected === category?.id && "active"}
                  onMouseDown={() => {
                    setSelected(category?.id);
                    window.scrollTo({
                      top: 0,
                      left: 0,
                    });
                    ReactGA.event({
                      category: "Select category in restaurant",
                      action: "Click",
                      value: `category name: ${category?.name}}`,
                    });
                  }}
                  ref={urRef}
                  key={idx}
                >
                  <Button>{category?.name}</Button>
                </li>
              ) : (
                <li
                  className={selected === category?.id && "active"}
                  key={idx}
                  onClick={(id) => {
                    handelNav(category?.id);
                  }}
                >
                  <Button>{category?.name}</Button>
                </li>
              );
            })}
          </ul>
        </div>
        <div ref={pharmacyItemRef}>
          {data?.pages?.map((page) =>
            page?.data?.data?.items?.data.map((item) => {
              return (
                <div className={`add-post-container add-cart`}>
                  <AddCartPost
                    item={item}
                    restaurantId={restaurant_id}
                    restaurantInfo={restaurant_info}
                    isOffCategory={isOffCategory}
                    restaurant_is_closed={restaurant_is_closed}
                    isbooking={!canorder}
                    currency={currency}
                    selected={selected}
                    theme={true}
                  />
                </div>
              );
            })
          )}
          <div
            className="ref_loading"
            ref={loadMoreRef}
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          ></div>
          <div>
            {isFetchingNextPage ? (
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div>
            ) : hasNextPage ? (
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <ViewCart room_uuid={room_uuid} />
      </>
    );
  };
  return (
    <div className="food">
      {showData()}
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        centered={false}
        className="view-cart-modal"
      >
        <Modal.Body className={`${lang === "ar" ? "ar_lang" : ""}`}>
          <Button className="close-btn" onClick={handleClose}>
            <i className="fa fa-times-circle-o"></i>
          </Button>
          <ViewCartModal />
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default InDiningItems;
