import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import HeaderInner from "../../Components/HeaderInner";
import Post1 from "../../assets/images/Flowers/Flower.png";
import { Button, Modal } from "react-bootstrap";
import ViewFlowerCartModal from "../../Components/ViewFlowerCartModal";
import FlowerCartPost from "../../Components/FlowerCartPost";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import useDraggableScroll from "use-draggable-scroll";
import axios from "../../axios";
import { useCartStore } from "../../store/useStore";
import PharmacyCartPost from "../../Components/PharmacyCartPost";
import { FlowerSubMenuAPI, ShowFlowerSubMenu } from "../../API/FlowersAPI";
import PharmacySubMenuLoader from "../../Components/Loaders/PharmacySubMenuLoader";
import ViewCart from "../../Components/ViewCart";
import { useInfiniteQuery } from "react-query";
import Search from "../../Components/Search";
import ReactGA from "react-ga4";
import Title from "../../new-components/title/Title";

function FlowersSubMenu() {
  const params = useParams();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(params.id);
  // const [flowerItems, setFlowerItems] = useState();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // const theme = location.state?.theme;
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";

  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const { cart } = useCartStore((state) => ({
    cart: state.cart,
  }));
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const lang = localStorage.getItem("lang");

  const Flower_uuid = params.flower_uuid;
  const {
    data,
    isLoading,
    isError,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery(
    `flower_sub_menu-${lang}-${selected}`,
    ({ pageParam = 1 }) =>
      FlowerSubMenuAPI({ room_uuid, Flower_uuid, selected, pageParam, lang }),
    {
      getNextPageParam: (data) => {
        const lastPage = data?.data?.data?.items?.links?.last_page;
        const currentPage = data?.data?.data?.items?.links?.current_page;
        return currentPage < lastPage ? currentPage + 1 : undefined;
      },
    }
  );

  let title;
  let terms_and_conditions;
  if (!isLoading) {
    if (data.pages[0].data.status == false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      // console.log("data", data.pages[0]);
      title = data?.pages[0]?.data.data.title;
      terms_and_conditions =
        data?.pages[0]?.data.data?.florist.terms_and_conditions;
    }
  }
  let loadMoreRef = useRef();

  useEffect(() => {
    if (!hasNextPage) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && fetchNextPage()),
      {
        root: null,
        margin: "0px",
        treshold: 1.0,
      }
    );

    const el = loadMoreRef && loadMoreRef.current;

    if (!el) {
      return;
    }

    observer.observe(el);
  }, [loadMoreRef.current, hasNextPage]);

  const devRef = useRef(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    const fix = document.getElementsByName("fixedSearch");
    console.log("devRef.current", fix);
    // if (devRef.current) {
    //   console.log(
    //     "devRef.current devRef.current devRef.current",
    //     devRef.current
    //   );
    //   devRef.current.classList.remove("fixedSearch");
    // }
    if (ref.current) {
      // console.log("ref.current", ref.current.children);

      const lists = ref.current.children;
      // console.log("ref", ref.current.children);
      // ref.current.children;
      Object.values(lists).map((list, idx) => {
        if (list.classList.contains("active")) {
          const itemWidth = list.offsetWidth;
          const scrollPosition = idx * itemWidth;

          ref.current.scrollTo({
            left: scrollPosition,
            behavior: "smooth",
          });
          //     // Your logic when the "active" class is present
          //     // console.log('The first child has the "active" class');
        }
      });
    }
  }, [data]);

  useEffect(() => {
    let tempTotal = 0;
    cart.map((item) => {
      tempTotal += parseFloat(item.price * item.count);
    });
    setTotal(tempTotal);
  }, [cart]);

  const flowerItemRef = useRef(null);

  useLayoutEffect(() => {
    if (devRef.current) {
      // console.log(
      //   "hi***********************",
      //   devRef.current.classList.contains("fixedSearch")
      // );
      if (devRef.current.classList.contains("fixedSearch")) {
        devRef.current.classList.remove("fixedSearch");
      }
      if (data?.pages[0].data?.data?.items?.data.length > 3) {
        console.log(devRef);
        const divAnimate =
          devRef.current.getBoundingClientRect().top - theme ? 0 : 70;
        const heightDiv = devRef.current.getBoundingClientRect().height;

        console.log("divAnimate", divAnimate);
        const onScroll = () => {
          if (divAnimate < window.scrollY) {
            console.log("ok");
            devRef.current.classList.add("fixedSearch");
            flowerItemRef.current.style.paddingTop = `${Math.floor(
              heightDiv
            )}px`;
          } else {
            devRef.current.classList.remove("fixedSearch");
            flowerItemRef.current.style.paddingTop = "0px";
          }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
      }
    }
  }, [data]);
  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={title} /> : <HeaderInner headerName={title} />}
      <main
        className="bg-white back-white pad-top-0"
        style={{ paddingBottom: "70px" }}
      >
        {isLoading ? (
          <PharmacySubMenuLoader />
        ) : (
          <>
            <span className="" ref={devRef}>
              <Search
                placeholder="Search for items"
                room_uuid={room_uuid}
                category="Flowers"
                className="mt-1"
              />
              {terms_and_conditions != "" &&
                (terms_and_conditions != undefined ? (
                  <>
                    <div
                      className="mx-4 divhtml"
                      dangerouslySetInnerHTML={{
                        __html: terms_and_conditions,
                      }}
                    />
                  </>
                ) : null)}

              <ul
                className="inline-list search"
                ref={ref}
                onMouseDown={onMouseDown}
              >
                {data?.pages[0]?.data?.data?.categories?.map((cat, idx) => {
                  return (
                    <li
                      className={selected == cat?.id && "active"}
                      onClick={() => {
                        setSelected(cat?.id);
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                        ReactGA.event({
                          category: "Select category in flowers",
                          action: "Click",
                          value: `category name: ${cat?.name}}`,
                        });
                      }}
                    >
                      <Button>{cat?.name}</Button>
                    </li>
                  );
                })}
              </ul>
            </span>
            <div className="items_dv" ref={flowerItemRef}>
              {data?.pages?.map((page) =>
                page?.data?.data?.items?.data?.map((item) => {
                  return (
                    <div className="add-post-container add-cart">
                      <PharmacyCartPost
                        item={item}
                        flowerId={params.flower_id}
                        title={title}
                        theme={theme}
                      />
                    </div>
                  );
                })
              )}

              <div
                className="ref_loading"
                ref={loadMoreRef}
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
              ></div>
              <div>
                {isFetchingNextPage ? (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden"></span>
                    </div>
                  </div>
                ) : hasNextPage ? (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden"></span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <ViewCart room_uuid={room_uuid} />
          </>
        )}
      </main>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        className="view-cart-modal"
      >
        <Modal.Body>
          <Button className="close-btn" onClick={handleClose}>
            <i className="fa fa-times-circle-o"></i>
          </Button>
          <ViewFlowerCartModal />
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default FlowersSubMenu;
