import React, { useEffect, useRef, useState } from "react";

import { Html5QrcodeScanner, Html5Qrcode } from "html5-qrcode";
import { useLocation, useNavigate } from "react-router-dom";

const ScanQrCode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const [loading, setLoading] = useState(false);
  const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
  var html5QrCode;
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (!check) {
      setCheck(true);
    }

    html5QrCode = new Html5Qrcode("reader");
    handleClickAdvanced();
    // html5QrCode = new Html5Qrcode("reader");

    // const oldRegion = document.getElementById("qr-shaded-region");
    // oldRegion && oldRegion.remove();
  }, []);

  //   if (check) {
  //     html5QrCode = new Html5Qrcode("reader");
  //   }

  const handleClickAdvanced = () => {
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      console.info(decodedResult, decodedText);
      //  props.onResult(decodedText);
      // alert(`decoded:__ ${decodedText}`);
      if (decodedText.includes("http")) {
        setLoading(true);
        window.location.replace(decodedText);
      }

      handleStop();
    };
    html5QrCode
      .start({ facingMode: "environment" }, qrConfig, qrCodeSuccessCallback)
      .then(() => {
        // const oldRegion = document.getElementById("qr-shaded-region");
        // if (oldRegion) oldRegion.innerHTML = "";
      });
  };

  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then((res) => {
          html5QrCode.clear();
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";

  return (
    <div className={theme ? "food" : ""}>
      <div className="scan">
        {loading && <p>Loading....</p>}
        {/* <p>{scannerData}</p> */}
        <div id="reader"></div>
        <button onClick={() => navigate(`/${room_uuid}/qr-scan`)}>
          Stop Camera
        </button>
      </div>
    </div>
  );
};

export default ScanQrCode;
