import React, { useEffect } from "react";
import HeaderInner from "../../Components/HeaderInner";
import CarRentalLoader from "../../Components/Loaders/CarRentalLoader";
import Search from "../../Components/Search";
import { Zoom } from "react-reveal";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ShowScanRoomDining } from "../../API/In-Room-DiningAPI";
import Carosle from "../../Components/Carosle";
import SearchSc from "../../Components/SearchSc";
import CombImg from "../../assets/images/cambo_meal.jpeg";
import BannerHome from "../../new-components/banner-home/BannerHome";
import axios from "../../axios";
import { t } from "i18next";

const InRoomDiningScan = () => {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const params = useParams();
  const navigate = useNavigate();
  const isbooking = rawPath.includes("RestaurantBooking");
  const lang = localStorage.getItem("lang");
  let theme = false
  let restaurant;
  let restaurantCategories;
  let images = [];
  let isVideo = false;
  let bgColor;
  let color;
  let has_combo_meals;
  let restaurant_info;

  let text;
  let r_uuid;
  let languages;
  let terms_and_conditions;


  const { data, isLoading, error } = ShowScanRoomDining(room_uuid, lang);

  if (!isLoading) {
    console.log("stata", data.data);
    if (data.data.status == false) {
      // navigate(`/${room_uuid}/qr-scan`);
    } else if (!error) {
      restaurant = data.data.data;
      r_uuid = restaurant.restaurant.id;
      restaurant_info = restaurant.restaurant;
      has_combo_meals = restaurant.has_combo_meals;
      theme = restaurant.use_new_design;
      localStorage.setItem("new_design", restaurant.use_new_design);

      restaurantCategories = restaurant.Restaurant_menuCategoriesResource;
      isVideo = restaurant.restaurant.real_video ? true : false;
      terms_and_conditions = restaurant.restaurant.terms_and_conditions;

      if (restaurant.restaurant.real_video) {
        if (restaurant.restaurant?.images?.length > 0) {
          images = [
            { image: restaurant.restaurant.real_video },
            ...restaurant.restaurant?.images,
          ];
        }
      } else {
        if (restaurant.restaurant?.images?.length > 0) {
          images = restaurant.restaurant?.images;
        }
      }
      color = data.data.data.color;
      languages = data.data.data.languages;
      bgColor = data.data.data.headerColor;
      text = data.data.data.center_header;

      if (restaurantCategories?.length == 1) {
        if (restaurantCategories[0]?.count_sub_categories != 0) {
          navigate(
            `/${room_uuid}/restaurant-menu/cat/${restaurantCategories[0].id}`,
            { replace: true }
          );
        } else {
          navigate(
            `/${room_uuid}/restaurant-menu/${restaurantCategories[0].id}`,
            { replace: true }
          );
        }
      }
      localStorage.setItem("currency", data.data.data.currency);
      document.documentElement.style.setProperty("--color", color);
      document.documentElement.style.setProperty("--color_t", `${color}b3`);
      document.documentElement.style.setProperty("--header_color", bgColor);
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      // });
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);



  const setItemWithExpiry = (key, value, ttl) => {
    const now = new Date();

    // `item` is an object which includes the value and the expiry time
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const EntryPoint = async () => {
    // axios.get(`/648ed77e-da78-4987-9b86-de515659fc2c`).then((res) => {
    await axios
      .get(`/${room_uuid}/restaurant-menu/entry-point`, {
        headers: {
          lang: lang,
        },
      })
      .then((res) => {

      });
  };

  useEffect(() => {
    if (data) {

      const isOpenBefore = localStorage.getItem("open_category")
      if (isOpenBefore == null || JSON.parse(isOpenBefore)?.expiry < new Date().getTime()) {
        EntryPoint()
        setItemWithExpiry("open_category", restaurant?.restaurant?.uuid, 300000);
      } else {

        if (restaurant?.restaurant?.uuid !== JSON.parse(isOpenBefore)?.value) {

          EntryPoint()
          setItemWithExpiry("open_category", restaurant?.restaurant?.uuid, 300000);

        }

      }
    }

  }, [data])
  return (

    isLoading ?
      <div className="home_loader">
        <div className="loader"></div>
      </div>
      :
      <div className={theme ? "food pb-2" : ""}>
        <HeaderInner
          headerName={
            lang == "EN" ? restaurant_info?.display_name : restaurant_info?.name
          }
          scan={true}
          languages={languages}
          theme={theme}
        />
        <main className="bg-white pad-top-0 resturant_category">
          <>
            {images?.length > 0 &&
              (theme ? (
                <>
                  <BannerHome
                    className={"bot_rad"}
                    headerImages={images}
                    isVideo={isVideo}
                    isOverllay={true}
                  />
                  <div
                    className={`res_info ${images?.length == 0 ? "mt-0" : ""}`}
                  >
                    {" "}
                    <div className="res_name">
                      <h5 className="">
                        {" "}
                        {lang == "EN"
                          ? restaurant_info.display_name
                          : restaurant_info.name}
                      </h5>
                      <p>
                        {restaurant_info?.cuisines?.map((cuisine, idx) => {
                          if (idx + 1 < restaurant_info?.cuisines.length) {
                            return `${cuisine.name}  |  `;
                          } else {
                            return `${cuisine.name}  `;
                          }
                        })}
                      </p>
                    </div>
                    {/* {restaurant_info?.show_booking == 1 && (
                <button
                  onClick={() =>
                    navigate(
                      `/${params.room_uuid}/BookTable/${restaurant_info.uuid}/${restaurant_info.id}?theme=true`
                    )
                  }
                  className="reg-btn"
                >
                  Book a Table
                </button>
              )} */}
                  </div>
                </>
              ) : (
                <header className="img-header m-0">
                  <Carosle
                    className={"category_food"}
                    headerImages={images}
                    isVideo={isVideo}
                  />
                </header>
              ))}

            <SearchSc
              placeholder="Search for Categories..."
              room_uuid={room_uuid}
              text={text}
            />
            {terms_and_conditions != "" &&
              (terms_and_conditions != undefined ? (
                <>
                  {/* <p className="text-center">Terms & Conditions</p> */}
                  <div
                    className="mx-4 divhtml"
                    dangerouslySetInnerHTML={{
                      __html: terms_and_conditions,
                    }}
                  />
                </>
              ) : null)}
            <ul className="display-list two-items restaurant-list mt-2 v2">
              {has_combo_meals && (
                <Zoom key={"001"}>
                  <li className={"restaurant-option"}>
                    <Link to={`/${room_uuid}/restaurant-menu/combo-meals`}>
                      <div className="closed-tag"></div> <div></div>
                      <div className="img-pnl">
                        <img src={CombImg} alt="" />
                      </div>
                      <div className="bg-layer"></div>
                      <p>{t("Combo Meals")}</p>
                    </Link>
                  </li>
                </Zoom>
              )}
              {restaurantCategories
                ? restaurantCategories.map((category, idx) => {
                  console.log(category.name, encodeURI(category.image));
                  return (
                    <Zoom key={idx}>
                      <li
                        className={
                          category?.is_closed
                            ? "closed restaurant-option"
                            : "restaurant-option"
                        }
                      // /:room_uuid/RestaurantBooking/RestaurantMenu/:id/:restaurnat_id/:cat_id
                      >
                        <Link
                          to={
                            category.count_sub_categories != 0
                              ? `/${room_uuid}/restaurant-menu/cat/${category.id}`
                              : `/${room_uuid}/restaurant-menu/${category.id}`
                          }
                        >
                          <div className="closed-tag"></div> <div></div>
                          {category?.is_closed && (
                            <span className="open_at">
                              {category.opens_at
                                ? `Opens at ${category.opens_at}`
                                : `Closed`}
                            </span>
                          )}
                          <div
                            className="img-pnl"
                          // style={{
                          //   backgroundImage: `url(${category.image})`,
                          // }}
                          >
                            <img src={encodeURI(category.image)} alt="" />
                          </div>
                          {/* {console.log(category.image.replace(/ /g, "%20"))} */}
                          <div className="bg-layer"></div>
                          <p>{category.name}</p>
                        </Link>
                      </li>
                    </Zoom>
                  );
                })
                : null}
            </ul>
          </>
        </main>
      </div>
  );
};

export default InRoomDiningScan;
