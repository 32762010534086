import React, { useEffect, useRef, useState } from "react";
import HeaderInner from "../Components/HeaderInner";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../axios";
import SpaLoader from "../Components/Loaders/SpaLoader";
import Iframe from "react-iframe";
import Title from "../new-components/title/Title";
import NewFooter from "../NewFooter";

function ThingsToDoItem() {
  const [urlParam, setUrlParam] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams.get("url");
    setUrlParam(url);
  }, []);

  console.log("urlParam", urlParam);


  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  // useEffect(() => {
  //   const getData = async () => {
  //     setLaoding(true);
  //     axios
  //       .get(`/${room_uuid}/location`)
  //       .then((res) => {
  //         if (res.data.status === false) {
  //           navigate(`/${room_uuid}/qr-scan`);
  //         } else {
  //           const data = res.data.data;
  //           setDataInfo(res.data.data);
  //           setLaoding(false);
  //         }
  //       })
  //       .catch((err) => {
  //         if (err?.response.status == 404) {
  //           navigate(`/not-found`);
  //         }
  //       });
  //   };
  //   getData();
  // }, []);

  // <HeaderInner headerName={"Taxi booking"} />
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={"Things To Do"} />
      ) : (
        <HeaderInner headerName={"Things To Do"} />
      )}
      <main className="home-main bg-white back-white book-table pad-top-0 taxi_book thing_to">

        <Iframe
          url={urlParam}
          // url="https://www.wikipedia.org/"
          width="100%"
          // height="80vh"
          id=""
          className="iframe_layar"
          display="block"
        // position="relative"
        />
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default ThingsToDoItem;
