import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./i18n";
import ReactGA from "react-ga4";

ReactGA.initialize("G-R2HT0ZEPHY");
ReactGA.send({ hitType: "pageview", page: window.location.pathname });
const queryClient = new QueryClient();
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById("root")
);
reportWebVitals();
