import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import AddCartPost from "../../Components/AddCartPost";

const AdditionalItems = ({
  openAdditional,
  setOpenAdditional,
  additional_items,
  order_business,
  theme,
}) => {
  console.log("order_business", order_business);
  const [firstRender, setFirstRednder] = useState(true);
  useEffect(() => {
    if (additional_items.length > 0 && firstRender) {
      setOpenAdditional(true);
      setFirstRednder(false);
    }
  }, [additional_items]);
  const handleCloseAdditional = () => {
    setOpenAdditional(false);
  };
  const currency = localStorage.getItem("currency");
  const lang = localStorage.getItem("lang");
  return (
    <Modal
      show={openAdditional}
      onHide={handleCloseAdditional}
      className={`${theme ? "food-modal" : ""}`}
      style={{ minHeight: "150px" }}
    >
      <Modal.Body
        style={{
          padding: "20px 10px",
          height: "auto",
        }}
      >
        <div
          className={`${lang == "AR" ? "ar_lang" : ""} `}
          style={{ position: "relative" }}
        >
          <div className="additional_header">
            <h6 className="additional_title">{t("Your items go with")}</h6>
            <span
              className="add_close"
              onClick={() => setOpenAdditional(false)}
            >
              {" "}
              <i class="fas fa-times"></i>
            </span>
          </div>
          {additional_items?.map((item, idx) => {
            return (
              <div className="additional_list">
                {item?.additional_items.map((ad_item, idx) => {
                  return (
                    <div className={`add-post-container add-cart`}>
                      <AddCartPost
                        item={ad_item}
                        restaurantId={order_business?.id}
                        restaurantInfo={order_business}
                        isOffCategory={false}
                        restaurant_is_closed={false}
                        isbooking={false}
                        theme={theme}
                        currency={currency}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className="additional_button">
            <button onClick={() => setOpenAdditional(false)}>
              {t("Proceed to Checkout")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AdditionalItems;
