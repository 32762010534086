import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import Entertainment1 from "../../assets/images/Entertainment/Entertainment-1.png";
import Entertainment2 from "../../assets/images/Entertainment/Entertainment-2.png";
import Entertainment3 from "../../assets/images/Entertainment/Entertainment-3.png";
import Entertainment4 from "../../assets/images/Entertainment/Entertainment-4.png";
import axios from "../../axios";
import { useEffect } from "react";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { ShowEntertainment } from "../../API/EntertainmentAPI";
import { ShowMaintenance } from "../../API/MaintenanceAPI";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";

import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";

function Maintenance() {
  const [enableClass, setEnableClass] = useState(true);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(false);
  // const [entertainmentCategories, setEntertainmentCategories] = useState();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const navigate = useNavigate();

  // console.log("rawPath", rawPath);
  const lang = localStorage.getItem("lang");

  // setTimeout(() => {
  //   setEnableClass(false);
  // }, 3000);
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  let flowers;
  let bgColor;
  let color;
  let text;
  let entertainmentCategories;
  const { data, isLoading, error } = ShowMaintenance(room_uuid, lang);
  if (!isLoading) {
    if (error) {
      if (error?.response.status == 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        flowers = data.data.data;
        entertainmentCategories = flowers?.companies;
        color = flowers?.color;
        bgColor = flowers?.header_color;
        text = flowers?.title;
        if (entertainmentCategories?.length == 1) {
          navigate(
            `/${room_uuid}/maintenance/companies/${entertainmentCategories[0].uuid}`,
            { replace: true }
          );
        }
      }
      // console.log("ShowEntertainment", flowers);
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={text} /> : <HeaderInner headerName={text} />}
      <main className="home-main bg-white pt-3">
        <div className="">
          {isLoading ? (
            <SpaLoader />
          ) : theme ? (
            <div className="box_food">
              {entertainmentCategories.length > 1 &&
                entertainmentCategories?.map((item, idx) => {
                  return (
                    <span
                      key={idx}
                      onClick={() =>
                        navigate(
                          `/${room_uuid}/maintenance/companies/${item.uuid}`,
                          {
                            state: { theme: true },
                          }
                        )
                      }
                    >
                      <div style={{ position: "relative" }}>
                        <img src={item?.logo} alt="" className="booking_img" />
                        <img src={Overllay} alt="" className="overlay" />
                        <div className="text_food">
                          <h5 className="">{item.name}</h5>
                        </div>{" "}
                      </div>
                    </span>
                  );
                })}
            </div>
          ) : entertainmentCategories.length > 1 ? (
            entertainmentCategories.map((x, idx) => {
              return (
                <div id={x.name} key={idx}>
                  <Zoom>
                    <div
                      className="con-img-container v3"
                      onClick={() =>
                        navigate(
                          `/${room_uuid}/maintenance/companies/${x.uuid}`
                        )
                      }
                    >
                      <div className="con-img">
                        <img src={x.logo} alt="" />
                        <p></p>
                      </div>
                    </div>
                    <div className="con-text-container">
                      <p>{x.name}</p>
                      <hr className="mb-0 dark" />
                    </div>
                  </Zoom>
                </div>
              );
            })
          ) : null}
        </div>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default Maintenance;
