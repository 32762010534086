import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CarBookModal from "./CarBookModal";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import Reveal from "react-reveal/Reveal";
import { Zoom } from "react-reveal";
import { t } from "i18next";

const CarRentalPost = ({ car, room_uuid }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  const currency = localStorage.getItem("currency");

  return (
    <>
      <Zoom key={car.id}>
        <li>
          <Button to="#" onClick={handleShow}>
            <div
              className="img-pnl"
              style={{
                backgroundImage: `url(${car.images[0].image})`,
              }}
            ></div>
            {/* <img src={car.images[0]} alt="" /> */}
            <div></div>
            <p style={{ height: "25px" }}>
              {car.car_type.name} - {car.car_model.name} - {car.year}
            </p>
            <h6 className="car_price" >{`${t("Starting from")} ${currency} ${car?.price} `} </h6>
          </Button>
        </li>
      </Zoom>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        className={`${theme ? "food-modal" : ""} car-rental-modal`}
      >
        <Modal.Body>
          <Button className="close-btn car" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24.054"
              height="24.625"
              viewBox="0 0 29 29"
            >
              <defs>
                <style></style>
              </defs>
              <g
                id="Group_38128"
                data-name="Group 38128"
                transform="translate(-4973 -1330)"
              >
                <circle
                  id="Ellipse_49"
                  data-name="Ellipse 49"
                  class="cls-1w"
                  cx="14.5"
                  cy="14.5"
                  r="10.5"
                  transform="translate(4973 1330)"
                ></circle>
                <path
                  id="Icon_ionic-ios-close-circle"
                  data-name="Icon ionic-ios-close-circle"
                  class="cls-2w"
                  d="M17.539,3.375a14.333,14.333,0,0,0-14.164,14.5,14.333,14.333,0,0,0,14.164,14.5A14.333,14.333,0,0,0,31.7,17.875,14.333,14.333,0,0,0,17.539,3.375Zm3.589,19.749L17.539,19.45,13.95,23.124a1.075,1.075,0,0,1-1.539,0,1.129,1.129,0,0,1,0-1.575L16,17.875,12.411,14.2a1.129,1.129,0,0,1,0-1.575,1.075,1.075,0,0,1,1.539,0L17.539,16.3l3.589-3.674a1.075,1.075,0,0,1,1.539,0,1.138,1.138,0,0,1,0,1.575l-3.589,3.674,3.589,3.674a1.138,1.138,0,0,1,0,1.575A1.063,1.063,0,0,1,21.127,23.124Z"
                  transform="translate(4969.625 1326.625)"
                ></path>
              </g>
            </svg>
          </Button>
          <CarBookModal car={car} room_uuid={room_uuid} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CarRentalPost;
