import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
import "./HotelServices.scss";
import PhoneInput from "react-phone-input-2";
import ViewLaundryCartModal from "../../Components/ViewLaundryCartModal";
import { object, string, array } from "yup";
import { useCartStore } from "../../store/useStore";
import axios from "../../axios";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import ReactGA from "react-ga4";
import { ShowLaundry } from "../../API/LaundryAPI";
import TaxInclusive from "../../Components/TaxInclusive.";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import { phoneFormat } from "../../constants/api";

function LaundryOrder() {
  const toast = useRef(null);

  let laundarySchema = object({
    name: string(),
    phone: string(),
    email: string().email(),
    pick_up_date: string(),
    pick_up_time: string(),
    // address: string(),
    laundry_id: string(),
    services: array(),
  });

  const [timeInput, setTimeInput] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const [showPref, setShowPref] = useState(false);
  const handlePrefClose = () => {
    setShowPref(false);
  };
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemChange, setItemChange] = useState();
  const [laundaryOrder, setLaundaryOrder] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [is_express, setIsExpress] = useState(false);
  const currency = localStorage.getItem("currency");

  const [dateInput, setDateInput] = useState();
  const params = useParams();
  const location = useLocation();
  const [errPhone, setErrPhone] = useState("");
  const vat = Number(params.vat);
  const min_amount = Number(params.min);
  const fee = Number(params.fee);
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const [preferences, setPreferences] = useState([]);

  const handleCheckboxChange = (itemId) => {
    if (preferences?.includes(itemId)) {
      setPreferences((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item !== itemId)
      );
    } else {
      setPreferences((prevCheckedItems) => [...prevCheckedItems, itemId]);
    }
  };
  const navigate = useNavigate();
  let laundryData;
  let timeSlots;
  let required_inputs;
  let has_exp;
  let discount_percentage;
  let images;
  let header_title;
  let laundry_preference;


  const { data, isLoading } = ShowLaundry(
    room_uuid,
    params.laundry_uuid,
    lang
  );
  if (!isLoading) {
    laundryData = data.data.data;
    timeSlots = laundryData?.timeSlots;
    images = laundryData?.header_images;
    header_title = laundryData?.header_title;
    laundry_preference = laundryData?.laundry_preference;

    has_exp = laundryData?.laundry.has_express;
    discount_percentage = laundryData?.laundry.discount_percentage;
    required_inputs = laundryData?.userInofRequired;

  }

  const [openMin, setOpenMin] = useState(false);

  const handleCloseMin = () => {
    setOpenMin(false);
  };

  const { laundryCart, setLaundryCart } = useCartStore((state) => ({
    laundryCart: state.laundryCart,
    setLaundryCart: state.setLaundryCart,
  }));
  let price = 0;
  laundryCart.map((item) => {
    item.services.map((service) => {
      if (service.id == item.service_id) {
        if (is_express) {
          price =
            Number(price) +
            (Number(item.quantity * service?.price) *
              Number(service?.express_percentage)) /
            100 +
            Number(service?.price * item.quantity);
          console.log("price", item.quantity * service?.price);
          price = price + fee;
          price = price + (price * vat) / 100;
        } else {
          price = price + Number(item.quantity) * Number(service.price);
          price = price + fee;
          price = price + (price * vat) / 100;
        }
      }
    });
  });
  const room_number = localStorage.getItem("room_number");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleTime = (e) => {
    setTimeInput(e.target.value);
  };
  const handleDate = (e) => {
    setDateInput(e.target.value);
  };
  if (laundryCart.length === 0) {
    navigate(`/${room_uuid}/hotel-services/laundry?${params.laundry_uuid}`);
  }
  const removeItem = (item) => {
    const tempItems = laundryCart.filter((a) => item.id !== a.id);
    setLaundryCart(tempItems);
    if (laundryCart.length === 0) {
      navigate(`/${room_uuid}/hotel-services/laundry?${params.laundry_uuid}`);
    }

    ReactGA.event({
      category: "Remove item from laundry ",
      action: "Click",
      value: ``,
    });
  };

  const changeitem = (item) => {

    setItemChange(item);
    handleShow();
  };
  const addMoreItem = (item) => {
    ReactGA.event({
      category: "Click on Add more Item in laundry page ",
      action: "Click",
      value: ``,
    });
    setItemChange(null);
    handleShow();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLaundaryOrder((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const currentTime = new Date();

    let hours = (currentTime.getHours() + 1).toString().padStart(2, "0");

    const minutes = currentTime.getMinutes().toString().padStart(2, "0");

    const currentDate = new Date();

    let day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().padStart(2, "0");
    if (hours === "24") {
      hours = "01";
      day = day + 1;
    }
    const formattedTime = `${hours}:${minutes}`;
    const formatDate = `${year}-${month}-${day}`;
    setDateInput(formatDate);
    if (timeSlots?.length > 0) {
      setTimeInput(timeSlots[0]);
    } else {
      setTimeInput(formattedTime);
    }
  }, []);
  const currentTimem = new Date(`${dateInput}T${timeInput}`);
  const dubaiTime = currentTimem.toLocaleString("en-US", {
    timeZone: "Asia/Dubai",
  });

  const dateObj = new Date(dubaiTime);
  const hoursD = dateObj.getHours().toString().padStart(2, "0");
  const minutesD = (dateObj.getMinutes() + 1).toString().padStart(2, "0");
  const time = `${hoursD}:${minutesD}`;

  const [errorEmail, setErrorEmail] = useState("");

  const checkValEmail =
    laundaryOrder.email.length > 0 && !/\S+@\S+\.\S+/.test(laundaryOrder.email);
  let checkPhone = false;
  if (required_inputs) {
    if (laundaryOrder.phone === "") {
      checkPhone = false;
    } else {
      checkPhone = true;
    }
  } else if (required_inputs === false) {
    checkPhone = true;
  }

  console.log("currentTimem");

  const checkInputs = () => {
    if (required_inputs && laundaryOrder.phone.length === 0) {
      setErrPhone("The phone field is required.");
    } else {
      setErrPhone("");
    }

    if (
      laundaryOrder.email.length > 0 &&
      !/\S+@\S+\.\S+/.test(laundaryOrder.email)
    ) {
      setErrorEmail("The email must be a valid email address.");
    } else {
      setErrorEmail("");
    }
  };

  const handleBook = async (e) => {
    e.preventDefault();
    if (min_amount > price) {
      setOpenMin(true);
    } else {
      checkInputs();
      if (!checkValEmail && checkPhone) {
        setLoading(true);
        const ordered = {
          phone: laundaryOrder.phone,
          email: laundaryOrder.email,
          name: laundaryOrder.name,
          pick_up_date: dateInput,
          pick_up_time: timeSlots.length > 0 ? timeInput : time,
          laundry_id: laundryData?.laundry?.id,
          services: laundryCart,
          is_express: is_express === true ? 1 : 0,
          redirect_url: `https://${window.location.host}/${room_uuid}/LaundryCallback`,
          laundry_preferences: preferences,

        };
        const anser = await laundarySchema.validate(ordered);
        axios
          .post(`/${room_uuid}/laundry/order`, anser, {
            headers: {
              lang: lang,
            },
          })
          .then((res) => {
            setIsDisabled(false);

            console.log("res", res);
            if (res.data.status === false) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: t(`${res.data.message}`),
                life: 4000,
              });
              setLoading(false);
            }
            if (res.data.status) {
              ReactGA.event({
                category: "Order in laundry page ",
                action: "Click",
                value: ``,
              });
              setLoading(false);
              // setLaundryCart([]);
              if (res?.data?.data?.payment_url) {
                window.location.replace(res?.data?.data?.payment_url);
              } else {
                navigate(`/${room_uuid}/hotel-services/laundry/order`, {
                  state: { metadata: res.data.data },
                });
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            setIsDisabled(false);
            console.log(err);
            setLoading(false);
          });
      }
    }
    // navigate("/HCOrderPlaced");
  };
  // console.log("is_express", has_exp);
  return (
    <div className="food pb-2">
      <Title title={header_title} />
      <main className="bg-white back-white laundry_page">
        <Toast ref={toast} position="bottom-center" />

        {laundryCart &&
          laundryCart.map((item) => {
            return (
              <div className="add-post-container add-cart mt-4">
                <div className="add-cart-post laun-pay new spa gray ">
                  <div className="add-cart-post-inner">
                    {/* <div
                className={
                  enableClass ? "animated-background bg-skimmer" : "d-none"
                }
              ></div> */}
                    <div
                      className="cross-btn-red"
                      onClick={() => removeItem(item)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 23 23"
                      >
                        <path
                          id="Icon_ionic-ios-close-circle"
                          data-name="Icon ionic-ios-close-circle"
                          d="M14.875,3.375a11.5,11.5,0,1,0,11.5,11.5A11.5,11.5,0,0,0,14.875,3.375Zm2.914,15.663-2.914-2.914-2.914,2.914a.884.884,0,1,1-1.25-1.25l2.914-2.914-2.914-2.914a.884.884,0,0,1,1.25-1.25l2.914,2.914,2.914-2.914a.884.884,0,0,1,1.25,1.25l-2.914,2.914,2.914,2.914a.888.888,0,0,1,0,1.25A.878.878,0,0,1,17.789,19.038Z"
                          transform="translate(-3.375 -3.375)"
                          fill="#f11027"
                        ></path>{" "}
                      </svg>
                    </div>
                    <span
                      className="img-pnl navigate"
                    // onClick={() => {
                    //   handleShow();
                    //   setModalImage(Post1);
                    // }}
                    >
                      <img
                        src={item.image}
                        alt="Post"
                        className="box-service-img"
                      />
                    </span>
                    <div
                      className="txt-pnl navigate"
                    // onClick={handleCartShow}
                    // onClick={() => navigate("/SpaAddService")}
                    >
                      <h2>{item.name}</h2>
                      <p></p>
                      <div className="flex-div align-item-center">
                        <h3>
                          {item.services.map((service) => {
                            if (service.id == item.service_id) {
                              return service.name;
                            }
                          })}
                        </h3>
                        <Button
                          className="reg-btn v2 service"
                          onClick={() => changeitem(item)}
                        >
                          {t("Change")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center"
          }}
        >
          {has_exp !== 0 && (
            <div class="col-6">
              <div class="mb-0 form-group laundray_check">
                <input
                  class="accented"
                  type="checkbox"
                  id="is_express"
                  name="is_express"
                  onChange={(e) => setIsExpress(e.target.checked)}
                />

                <label class=" form-check-label ms-1" for="is_express">
                  Express Service
                </label>
              </div>
            </div>
          )}
          {laundry_preference?.length > 0 && (
            <span
              onClick={() => setShowPref(true)}
              className="select_preference"
            >
              Preferences
            </span>
          )}
        </div>

        {fee !== 0 && (
          <div
            className="full-heading flex order-total"
            style={{ marginBottom: "-14px" }}
          >
            <p> {t("Service Fee")}</p>
            <p>
              {currency} {fee}
            </p>
          </div>
        )}
        {discount_percentage != null && (
          <div
            className="full-heading flex order-total"
            style={{ marginBottom: "-14px" }}
          >
            <p> {t("Discount Percentage")}</p>
            <p>{discount_percentage}</p>
          </div>
        )}
        {vat !== 0 && (
          <div
            className="full-heading flex  order-total"
            style={{ marginBottom: "-14px" }}
          >
            <p> {t("VAT")}</p>
            <p>{vat}%</p>
          </div>
        )}
        <div className="full-heading flex m-0 order-total mb-2">
          <p> {t("Order Total")}</p>
          <span className="d-flex">
            {discount_percentage && (
              <p className="laundry_discount_percentage">
                {`${currency} `}
                {discount_percentage
                  ? price - price * Number(discount_percentage / 100)
                  : price}
              </p>
            )}
            <p
              className={
                discount_percentage
                  ? "laundry_pasic_price"
                  : "laundry_discount_percentage"
              }
            >
              {`${currency} `}
              {price}
            </p>
          </span>
        </div>
        <div className="full-div padd-div px-4">
          <Link
            className="reg-btn big w-100 more-items px-4"
            onClick={addMoreItem}
          >
            <i className="fa fa-plus-square"></i>{" "}
            <span>{`${t("Add More Items")}`} </span>
            <span></span>
          </Link>
        </div>
        <div className="padd-div">
          <Form onSubmit={handleBook}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                value={` ${t("Room number")} ${room_number}`}
                placeholder="Enter Your Room # (Optional)"
                disabled={true}
              />
            </Form.Group>
            <Row>
              <Col xs="6">
                <Form.Group className="mb-3" controlId="formBasicname">
                  <label>Pickup date</label>

                  <Form.Control
                    type="date"
                    value={dateInput}
                    min={new Date().toISOString().slice(0, 10)}
                    className="dt-global"
                    onChange={handleDate}
                  />
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group className="mb-3" controlId="formBasicname">
                  {laundryData?.timeSlots.length > 0 ? (
                    <select
                      className=" dt-global form-control"
                      onChange={(e) => setTimeInput(e.target.value)}
                    >
                      {Object.values(
                        laundryData?.timeSlots.map((item) => {
                          return <option value={item}>{item}</option>;
                        })
                      )}
                    </select>
                  ) : (
                    <>
                      <label>Pickup time</label>

                      <Form.Control
                        type="time"
                        className="dt-global"
                        value={timeInput}
                        onChange={handleTime}
                      />
                    </>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group
              className="mb-3 dark-placeholder"
              controlId="formBasicname"
            >

              <Form.Control
                type="text"
                placeholder={`${t(
                  `Your Name ${required_inputs ? "" : "(Optional)"}`
                )}`}
                name="name"
                value={laundaryOrder.name}
                onChange={handleInputChange}
                required={required_inputs}
              />
            </Form.Group>
            <Form.Group
              className="mb-3 dark-placeholder"
              controlId="formBasicname"
            >
              <Form.Control
                type="emial"
                placeholder={`${t(
                  `Your Email ${required_inputs ? "" : "(Optional)"}`
                )}`}
                name="email"
                // value={laundaryOrder.email}
                onChange={handleInputChange}
                required={required_inputs}
              />
            </Form.Group>
            {errorEmail !== "" && <span className="err_msg">{t(`${errorEmail}`)}</span>}
            <Form.Group className="mb-3 w-100 ">
              <PhoneInput
                inputProps={{
                  required: required_inputs,
                }}
                country={phoneFormat}
                containerclassName="ml-4"
                placeholder="50 123 4567"
                value={laundaryOrder.phone}
                onChange={(phone) => {
                  setLaundaryOrder((prev) => ({ ...prev, phone }));
                }}
              />
            </Form.Group>
            {errPhone !== "" && (
              <span className="err_msg">{t(`${errPhone}`)}</span>
            )}


            <div className="spacer-10"></div>

            <div className="spacer-40"></div>
            <div className="padd-div text-center mt-3">
              <Button
                type="submit"
                className="reg-btn order-btn"
                // onClick={handleBook}
                disabled={isDisabled || loading}
              >
                {`${t("Order")}`}
              </Button>
            </div>
          </Form>
        </div>
        <div className="padd-div text-center mb-70">
          <h3 className="">
            <TaxInclusive />
          </h3>
        </div>
        <NewFooter />
      </main>

      <ViewLaundryCartModal
        is_express={is_express}
        show={show}
        handleClose={handleClose}
        laundry_uuid={params.laundry_uuid}
        itemChange={itemChange}
        discount_percentage={discount_percentage}
        theme={true}
        images={images}
      />

      <Modal
        show={showPref}
        className={`food-modal custom-width lay_pref`}
        onHide={handlePrefClose}
      >

        <Modal.Body className="p-4" style={{ color: "#e7e7e7" }}>
          <h2>{t("Laundry Preferences")}</h2>
          {laundry_preference?.map((item) => {
            return (
              <div className="pref_list">
                <span className="pref_name">{item?.name}</span>
                <input
                  type="checkbox"
                  class="checkbox-option"
                  checked={preferences.includes(item.id)}
                  onChange={(e) => {
                    handleCheckboxChange(item.id);
                  }}
                  data-gtm-form-interact-field-id="0"
                />
              </div>
            );
          })}

          <Row className="mt-4">
            <Col lg="6" md="6" xs="6" className="p-0 px-2">
              <Button
                className="reg-btn big laundary-btn mb-0"
                style={{ marginBottom: "0px !important" }}
                onClick={handlePrefClose}
              >
                {t(`Cancel`)}
              </Button>
            </Col>
            <Col lg="6" md="6" xs="6" className="p-0  pl-2">
              <Button
                className="reg-btn big laundary-btn mb-0"
                style={{ marginBottom: "0px !important" }}
                onClick={handlePrefClose}
              >
                {t(`Save`)}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={openMin}
        onHide={handleCloseMin}
        className={`laundary-modal custom-width b-r`}
        style={{ marginTop: "200px" }}
      >
        <Modal.Body
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p className="order-model">
            {" "}
            {t("Order amount must be at least")} ({currency} {min_amount})
          </p>
          <div>
            <Button
              onClick={handleCloseMin}
              className="reg-btn v2 service"
              style={{ textAlign: "center", padding: "8.5px 15px !important" }}
            >
              {t(`OK`)}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default LaundryOrder;
