import React, { useRef } from "react";
import Carosle from "./Components/Carosle";
import MenuList from "./Components/MenuList";
import HeaderInner from "./Components/HeaderInner";
import { useNavigate, useParams } from "react-router-dom";

import HomeLoader from "./Components/Loaders/HomeLoader";
import { ShowHomeMenu } from "./API/HomeMenuAPI";
import ErrorPage from "./Components/ErrorPage";
import Footer from "./Components/Footer";

function HomeMenu() {
  const lang = localStorage.getItem("lang");
  // console.log("lang", lang);
  const params = useParams();
  const colorRef = useRef(null);
  let menu;
  let bgColor;
  let color;
  let headerImages;
  let logo;
  let services;
  let languages;
  let has_extra_services;
  let show_wifi_access = false
  let notFound = "";
  const navigate = useNavigate();
  const { data, isLoading, error } = ShowHomeMenu(params.room_uuid, lang);
  if (!isLoading) {
    // console.log("data.data.status", data.data);
    if (data.data.status === false) {
      navigate(`/${params.room_uuid}/qr-scan`);
      // notFound = "Room Not Found";
    } else {
      menu = data.data.data;
      languages = menu.Languages;
      // console.log("languages", languages);
      // const images = menu?.header_images.map((image) => {
      //   return image.image;
      // });
      // console.log("images", images);
      headerImages = menu.realvideo
        ? [{ image: menu.realvideo }, ...menu?.header_images]
        : menu?.header_images;
      logo = menu?.logo;
      show_wifi_access = menu?.show_wifi_access;

      services = menu?.activeServices;
      localStorage.setItem("has_extra_services", menu?.has_extra_services);
      localStorage.setItem(
        "is_grand_millennium_barsha_hotel",
        menu?.is_grand_millennium_barsha_hotel
      );
      has_extra_services = menu?.has_extra_services;
      bgColor = menu?.header_color;
      color = menu?.color;
      // console.log("menumenu", menu);
      localStorage.setItem("logo", logo);
      // colorRef.current.style.setProperty('--color', menu?.color);
      document.documentElement.style.setProperty("--color", menu?.color);
      document.documentElement.style.setProperty(
        "--color_t",
        `${menu?.color}b3`
      );
    }
    // localStorage.setItem("color", color)
    // localStorage.setItem("bgcolor", bgColor)
  }
  // useEffect(()=>{

  // },[isLoading])
  // useEffect(() => {
  //   const getData = async () => {
  //     // axios.get(`/648ed77e-da78-4987-9b86-de515659fc2c`).then((res) => {
  //     setLoading(true);

  //     axios.get(`/${params.room_uuid}`).then((res) => {
  //       const data = res?.data.data;
  //       if (res.data.status == false) {
  //         setError(res.data.message)
  //       }
  //       // console.log("res", res);
  //       setHeaderImages(data?.header_images);
  //       setLogo(data?.logo);
  //       setServices(data?.activeServices);
  //       setLoading(false);
  //       // console.log("d", data);
  //     }).catch((err) => {
  //       setLoading(false);
  //       setError(err?.message)
  //       // console.log("err", err);
  //     })
  //   };
  //   getData();
  // }, []);
  const showData = () => {
    if (isLoading) {
      return <HomeLoader />;
    }
    if (error) {
      return <ErrorPage des={error} />;
    }
    // if (notFound) {
    //   return <ErrorPage des={notFound} />;
    // }
    return (
      <>
        <HeaderInner
          logo={logo}
          bgColor={bgColor}
          color={color}
          languages={languages}
        />
        <main className="home-main pad-top-0">
          <Carosle
            className={"dot-bar less-margin small-dots"}
            headerImages={headerImages}
            isFirst={true}
          />
          <div className="spacer-10 full-div"></div>
          <MenuList
            services={services}
            loading={isLoading}
            has_extra_services={has_extra_services}
            show_wifi_access={show_wifi_access}
          />{" "}
        </main>
        {/* <Footer /> */}
      </>
    );
  };

  return (
    <>
      {/* <HeaderInner logo={logo} bgColor={bgColor}
        color={color} /> */}
      {/* <main className="home-main pad-top-0"> */}
      {showData()}
      {/* </main> */}
    </>
  );
}
export default HomeMenu;
